import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useRequest from "../../../../components/hook/use-request";
import { careerSlice } from "../../../../redux/slice/career-slice";
import { handleResponse } from "../../../../utils/handleResponse";
import DashLoader from "../../../../components/global/dash-loader";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import { replaceObjectKeys } from "../../../../utils/utilsFunction";
import ViewsCard from "../../../../components/dashboard/viewsCard";
import PropTypes from "prop-types";

function HeroSection({ heroData }) {
  const newKeys = ["Title", "Body", "Paragraph", "Button"];

  const newHeroData = replaceObjectKeys(heroData, newKeys);
  // console.log("Rendered hero");

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Hero Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center md:justify-items-start gap-4">
        {Object.entries(newHeroData).map(([element, desc]) => {
          return (
            <div key={element} className="flex flex-col gap-3 font-semibold">
              <h3>{element}</h3>
              <ViewsCard content={desc} />
            </div>
          );
        })}
      </div>
    </Section>
  );
}

HeroSection.propTypes = {
  heroData: PropTypes.object.isRequired,
};

// About us section
function AboutSection({ aboutData }) {
  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="About Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 justify-items-center md:justify-items-start gap-4">
        {aboutData.map((info, infoIdx) => {
          return (
            <div key={infoIdx} className="flex flex-col gap-3">
              <SectionHeading
                content={`About ${infoIdx + 1}`}
                className="text-[15px]"
              />
              <div className="flex gap-3">
                <ViewsCard content={info.title} label={"Title"} />
                <ViewsCard content={info.body} label={"Body"} />
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
}
AboutSection.propTypes = {
  aboutData: PropTypes.array.isRequired,
};

// Section one
function JoinUs({ sectionData }) {
  const newKeys = ["Title", "Contact Button"];

  const newSectionData = replaceObjectKeys(sectionData, newKeys);

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content={sectionData?.title} />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center md:justify-items-start gap-4">
        {Object.entries(newSectionData).map(([element, desc]) => {
          return (
            <div key={element} className="flex flex-col gap-3 font-semibold">
              <h3>{element}</h3>
              <ViewsCard content={desc} />
            </div>
          );
        })}
      </div>
    </Section>
  );
}
JoinUs.propTypes = {
  sectionData: PropTypes.object.isRequired,
};

// Section two
function TalkToUsSection3({ sectionData }) {
  const newKeys = ["Title", "Contact Button"];

  const newSectionData = replaceObjectKeys(sectionData, newKeys);

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Talk To Us Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center md:justify-items-start gap-4">
        {Object.entries(newSectionData).map(([element, desc]) => {
          return (
            <div key={element} className="flex flex-col gap-3 font-semibold">
              <h3>{element}</h3>
              <ViewsCard content={desc} />
            </div>
          );
        })}
      </div>
    </Section>
  );
}
TalkToUsSection3.propTypes = {
  sectionData: PropTypes.object.isRequired,
};

function CareersV() {
  const loading = useSelector((state) => state.careerPage.loading);
  const error = useSelector((state) => state.careerPage.error);
  const careerData = useSelector((state) => state.careerPage.data);

  const { makeRequest: getCareersData } = useRequest("GET", {}, careerSlice);

  useEffect(() => {
    const getData = async () => {
      const [, statusCode, response] = await getCareersData("/career");

      handleResponse(
        response,
        statusCode,
        error,
        careerData,
        "Successfully fetched!"
      );
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && !careerData) return <DashLoader message="Loading..." />;

  return (
    <div className="flex flex-col gap-8 pt-6 pb-10">
      <h1 className="text-xl self-center md:text-xl font-semibold">
        Career Page
      </h1>
      {careerData ? (
        <div className="flex flex-col gap-8">
          <HeroSection heroData={careerData?.hero} />
          <AboutSection aboutData={careerData?.about} />
          <JoinUs sectionData={careerData?.sectionOne} />
          <TalkToUsSection3 sectionData={careerData?.sectionTwo} />
        </div>
      ) : (
        <span>Fetching Data ...</span>
      )}
    </div>
  );
}

export default CareersV;
