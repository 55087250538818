import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slice/user-slice";
import homePageReducer from "./slice/home-slice";
import projectReducer from "./slice/project-slice";
import blogReducer from "./slice/blog-slice";
import serviceReducer from "./slice/service-slice";
import careerReducer from "./slice/career-slice";
import roleReducer from "./slice/role-slice";
import aboutReducer from "./slice/about-slice";
import partnerReducer from "./slice/partner-slice";

const store = configureStore({
  reducer: {
    user: userReducer,
    homePage: homePageReducer,
    projectPage: projectReducer,
    blogPage: blogReducer,
    servicePage: serviceReducer,
    careerPage: careerReducer,
    rolePage: roleReducer,
    aboutPage: aboutReducer,
    partnerPage: partnerReducer,
  },
});

export default store;
