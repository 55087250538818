import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Icons } from "../global/icon";
import PropTypes from "prop-types";
import { defaultNavigationData } from "../../utils/navigation";

const SidebarItem = ({ item, closeSidebar }) => {
  const [isOpen, setIsOpen] = useState(false);

  const hasChildren = item.children && item.children.length > 0;
  // Split the path into segments
  const segments = item.path.split("/");
  const isSubparent = segments.length === 2 && segments[0] !== "";

  return (
    <div className="">
      {/* Parent */}
      {hasChildren && (
        <div
          className={`font-semibold text-sm ${
            isSubparent ? "font-normal" : ""
          }`}
        >
          <div className="flex gap-2 focus:outline-none">
            <Link
              to={`/dashboard/projects/${item.path}`}
              className={`flex gap-2`}
              onClick={closeSidebar}
            >
              <span>{item.name}</span>
            </Link>
            <button onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? (
                <Icons.Enlarge className="w-4 h-4 self-center" />
              ) : (
                <Icons.Collapse className="w-4 h-4 self-center" />
              )}
            </button>
          </div>
        </div>
      )}

      {/* Child */}
      {!hasChildren && (
        <div className="flex gap-4 text-sm">
          <Link
            to={`/dashboard/projects/${item.path}`}
            className={`${item.path === "blogs" ? "font-semibold" : ""}`}
            onClick={closeSidebar}
          >
            <span>{item.name}</span>
          </Link>
        </div>
      )}
      {/* To open children */}
      {hasChildren && isOpen && (
        <div className="ml-5">
          {item.children.map((child, index) => (
            <SidebarItem key={index} item={child} closeSidebar={closeSidebar} />
          ))}
        </div>
      )}
    </div>
  );
};

SidebarItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  closeSidebar: PropTypes.func,
};

function ProjectSidebar({ onClick }) {
  return (
    <div className="w-full flex flex-col gap-16 items-center px-3 overflow-y-auto noScrollbar">
      {/* </div> */}
      <div className="flex flex-col gap-5">
        {defaultNavigationData.map((item, itemIndex) => {
          return (
            <li key={itemIndex} className="list-none">
              <SidebarItem item={item} closeSidebar={onClick} />
            </li>
          );
        })}
      </div>
    </div>
  );
}

ProjectSidebar.propTypes = {
  onClick: PropTypes.func,
};

export default ProjectSidebar;
