import React, { useState } from "react";
import { Link } from "react-router-dom";
import BlogsCardOptions from "./blogsCardOptions";
import { useSelector } from "react-redux";
import useRequest from "../hook/use-request";
import { blogSlice } from "../../redux/slice/blog-slice";
import { handleResponse } from "../../utils/handleResponse";

function BlogsViewCard({
  title,
  url,
  author,
  readTime,
  image,
  alt,
  id,
  isPublished,
  className,
}) {
  const [action, setAction] = useState("");
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const error = useSelector((state) => state.blogPage.error);
  const blogData = useSelector((state) => state.blogPage.data);

  const { makeRequest: publishBlog } = useRequest("PATCH", {}, blogSlice);

  function handleOptionsOpenAndClose(option) {
    setIsOptionsOpen(option);
  }
  async function handlePublish() {
    handleOptionsOpenAndClose(false);
    setAction("Publishing...");
    const [, statusCode, response] = await publishBlog(`/blog/publish/${id}`);
    handleResponse(
      response,
      statusCode,
      error,
      blogData,
      "Blog Published Successfully"
    );
    setAction("");
  }

  return (
    <div className="relative bg-white border border-slate-200 rounded-lg shadow-md w-full min-w-[300px] max-w-[300px] min-h-[300px] md:min-h-[400px]">
      <Link
        to={url}
        className={`flex flex-col md:flex-shrink-0 w-full h-full ${className}`}
      >
        {/* Image section */}
        <div className="w-full h-full border-b border-slate-200">
          <img
            src={image}
            alt={alt}
            className="w-full h-[150px] object-cover"
          />
        </div>

        {/* Content section */}
        <div className="px-3 py-5 w-full h-full space-y-5">
          <div className="flex justify-between">
            <img
              src="/icons/star_icon.svg"
              alt="Star icon"
              className="w-[18px] h-[18px]"
            />
            <p className="text-xs italic">{readTime}</p>
          </div>

          <div className="space-y-2">
            <h3 className="font-semibold text-lg">{title}</h3>
            <div className="flex gap-2 text-xs">
              <p className="font-semibold">Author: </p>
              <p className="italic">{author}</p>
            </div>
          </div>

          <p className="text-xs">{isPublished ? "Published" : "Unpublished"}</p>
        </div>
      </Link>
      <BlogsCardOptions
        id={id}
        isPublished={isPublished}
        isOptionOpen={isOptionsOpen}
        action={action}
        handleOptionsOpenAndClose={handleOptionsOpenAndClose}
        handlePublish={handlePublish}
      />
    </div>
  );
}

export default BlogsViewCard;
