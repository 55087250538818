import React from "react";
import { Icons } from "../global/icon";
import { useSelector } from "react-redux";
import { Link, useLoaderData, useLocation } from "react-router-dom";

function BlogsCardOptions({
  className,
  id,
  isPublished,
  action,
  setAction,
  isOptionOpen,
  handleOptionsOpenAndClose,
  handlePublish,
}) {
  const loading = useSelector((state) => state.blogPage?.loading);

  const location = useLocation();
  const staticURL = location.pathname.replace("view", "blog");
  const currentPageUrlArr = location.pathname.split("/");
  const currentPage = currentPageUrlArr[currentPageUrlArr.length - 1];

  return (
    <div className={`absolute right-4 bottom-8 ${className}`}>
      {/* // <div className={`self-start ${className}`}> */}
      {loading ? (
        <div>{action}</div>
      ) : (
        <div className="relative">
          <button
            className="w-fit h-fit rounded-lg px-4 py-0.5"
            onClick={() => handleOptionsOpenAndClose(true)}
          >
            <Icons.VerticalDots className="w-5" />
          </button>
          {isOptionOpen && (
            <div className="absolute bottom-full left-full bg-white z-30 flex flex-col gap-1 border border-slate-300 rounded-md">
              {/* Cancel button */}
              <button
                className="w-fit h-fit self-end mt-1 mr-1"
                onClick={() => handleOptionsOpenAndClose(false)}
              >
                <Icons.Cancel className="w-5 h-9" strokeWidth={2} />
              </button>
              <div className="list-none">
                {["Publish", "Delete", "Edit"].map((option, idx) => {
                  return (
                    <div key={idx}>
                      {option === "Edit" ? (
                        <Link
                          to={staticURL}
                          className={`border-b border-b-gray-200 p-0 py-2 px-6 cursor-pointer`}
                        >
                          {option}
                        </Link>
                      ) : (
                        <p
                          onClick={
                            option === "Publish" ? handlePublish : () => {}
                          }
                          className={`border-b border-b-gray-200 py-2 px-6 cursor-pointer`}
                        >
                          {option}
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default BlogsCardOptions;
