const staticNav = "static";
const blogs = "blogs";
const views = "views";

export const defaultNavigationData = [
  {
    name: "Static",
    path: `${staticNav}`,
    children: [
      {
        name: "Home",
        path: `${staticNav}/home`,
      },
      {
        name: "Projects",
        path: `${staticNav}/project`,
      },
      {
        name: "Services",
        path: `${staticNav}/service`,
      },
      {
        name: "Career",
        path: `${staticNav}/career`,
      },
      {
        name: "Role",
        path: `${staticNav}/role`,
      },
      {
        name: "About",
        path: `${staticNav}/about`,
      },
      {
        name: "Partner",
        path: `${staticNav}/partner`,
      },
    ],
  },
  {
    name: "Blogs",
    path: `${blogs}`,
    children: [
      {
        name: "Blog",
        path: `${blogs}/blog`,
      },
      {
        name: "View",
        path: `${blogs}/view`,
      },
    ],
  },
  {
    name: "Views",
    path: `${views}`,
    children: [
      {
        name: "Home",
        path: `${views}/home`,
      },
      {
        name: "Project",
        path: `${views}/project`,
      },
      {
        name: "Service",
        path: `${views}/service`,
      },
      {
        name: "Career",
        path: `${views}/career`,
      },
      {
        name: "Role",
        path: `${views}/career/role`,
      },
      {
        name: "About",
        path: `${views}/about`,
      },
      {
        name: "Partner",
        path: `${views}/partner`,
      },
    ],
  },
];

export const addItem = (items, path, newItem) => {
  return items.map((item) => {
    if (item.path === path) {
      return {
        ...item,
        children: item.children ? [...item.children, newItem] : [newItem],
      };
    }
    if (item.children) {
      return {
        ...item,
        children: addItem(item.children, path, newItem),
      };
    }
    return item;
  });
};
