import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import DashLoader from "../../../../components/global/dash-loader";
import {
  reverseTransformApiData,
  transformApiData,
} from "../../../../utils/transformData";
import { debounce, isEqual } from "lodash";
import { handleResponse } from "../../../../utils/handleResponse";
import { useEffect, useRef, useState } from "react";
import useRequest from "../../../../components/hook/use-request";
import { useSelector } from "react-redux";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import InputComponent from "../../../../components/dashboard/inputComponent";
import Label from "../../../../components/dashboard/label";
import InputField from "../../../../components/dashboard/inputField";
import TextArea from "../../../../components/dashboard/textArea";
import { serviceSlice } from "../../../../redux/slice/service-slice";
import {
  aboutFieldArr,
  aboutTemplates,
  heroSection,
  heroTemplates,
  servicesFieldArr,
  servicesTemplates,
  talkToUs,
  talkToUsTemplates,
} from "../../../../utils/dashboardFormData/otherPages/servicePageData";
import RemoveButton from "../../../../components/dashboard/removeButton";
import AddButton from "../../../../components/dashboard/addButton";
import { serviceSectionMap } from "../../../../utils/sectionMapData";
import FormWrapper from "../../../../components/dashboard/formWrapper";

function HeroSection() {
  const { register } = useFormContext();

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Hero Section" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        {heroSection.map((field, index) => {
          return (
            <InputComponent key={index} className="">
              <Label htmlFor={field.id} label={field.label} />
              {field.type === "text" && (
                <InputField
                  type={field.type}
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
              {field.type === "textArea" && (
                <TextArea
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
            </InputComponent>
          );
        })}
      </div>
    </Section>
  );
}

function AboutSection() {
  const { register, control } = useFormContext();
  const {
    fields: aboutFields,
    append: appendAbout,
    remove: removeAbout,
  } = useFieldArray({
    control,
    name: "about",
  });
  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="About Section" />
      <div className="space-y-5">
        {/* Field array section */}
        <div className="md:col-span-2">
          <AddButton
            onClick={() =>
              appendAbout({
                aboutBody: "",
                aboutPara: "",
              })
            }
            content="Add about"
            className={"gap-2"}
          />
        </div>

        {/* Field Array */}
        <div className="w-full col-span-1 md:col-span-2 gap-4">
          {aboutFields.map((about, aboutIdx) => {
            return (
              <div
                key={about.id}
                className="flex flex-col gap-4 w-full self-center md:self-start"
              >
                <SectionHeading
                  content={`About ${aboutIdx + 1}`}
                  className="bg-slate-700 text-white"
                />
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6 ">
                  {aboutFieldArr.map((entity, entityIdx) => {
                    return (
                      <InputComponent key={entityIdx} className="">
                        <Label htmlFor={entity.id} label={entity.label} />
                        {entity.type === "text" && (
                          <InputField
                            type={entity.type}
                            name={`about[${aboutIdx}].${entity.name}`}
                            id={entity.id}
                            placeholder={entity.placeholder}
                            register={register(
                              `about[${aboutIdx}].${entity.name}`
                            )}
                          />
                        )}
                        {entity.type === "textArea" && (
                          <TextArea
                            name={`about[${aboutIdx}].${entity.name}`}
                            id={entity.id}
                            placeholder={entity.placeholder}
                            register={register(
                              `about[${aboutIdx}].${entity.name}`
                            )}
                          />
                        )}
                      </InputComponent>
                    );
                  })}

                  {aboutIdx > 0 && (
                    <RemoveButton
                      className={"self-center mb-1"}
                      onClick={() => removeAbout(aboutIdx)}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
}

// Services Stacks
function ServicesStacks({ control, register, serviceIdx }) {
  const {
    fields: stackFields,
    append: appendStack,
    remove: removeStack,
  } = useFieldArray({
    control,
    name: `services.${serviceIdx}.stacks`,
  });
  return (
    <div className="flex flex-col gap-3 col-span-2 w-full">
      <SectionHeading content="Stacks" />
      <div className="flex flex-col gap-3">
        {/* Field Array */}
        <div className="">
          <AddButton
            onClick={() => appendStack("")}
            content="Add Stack"
            className={"gap-2"}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-3 w-full">
          {stackFields.map((stack, stackIdx) => {
            return (
              <div key={stack.id} className="flex gap-2 w-full">
                <InputComponent className="">
                  <Label htmlFor={"Stack"} label={`stack ${stackIdx + 1}`} />
                  <InputField
                    type={stack.type}
                    name={`services[${serviceIdx}].stacks[${stackIdx}]`}
                    id={"stack"}
                    placeholder={`stack ${stackIdx + 1}`}
                    register={register(
                      `services[${serviceIdx}].stacks[${stackIdx}]`
                    )}
                  />
                </InputComponent>

                {stackIdx > 0 && (
                  <RemoveButton
                    className={"self-end mb-1"}
                    onClick={() => removeStack(stackIdx)}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function ServicesSection() {
  const { register, control } = useFormContext();
  const {
    fields: servicesFields,
    append: appendService,
    remove: removeService,
  } = useFieldArray({
    control,
    name: "services",
  });
  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Services Section" />
      <div className="space-y-5">
        {/* Field array section */}
        <div className="md:col-span-2">
          <AddButton
            onClick={() =>
              appendService({
                servicesTitle: "",
                servicesBody: "",
                stacks: [""],
              })
            }
            content="Add service"
            className={"gap-2"}
          />
        </div>

        {/* Field Array */}
        <div className="w-full col-span-1 md:col-span-2 space-y-5">
          {servicesFields.map((service, serviceIdx) => {
            return (
              <div
                key={service.id}
                className="flex flex-col gap-4 w-full self-center md:self-start"
              >
                <SectionHeading
                  content={`Service ${serviceIdx + 1}`}
                  className="bg-slate-700 text-white"
                />
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
                  {servicesFieldArr.map((entity, entityIdx) => {
                    return (
                      <InputComponent key={entityIdx} className="">
                        <Label htmlFor={entity.id} label={entity.label} />
                        {entity.type === "text" && (
                          <InputField
                            type={entity.type}
                            name={`services[${serviceIdx}].${entity.name}`}
                            id={entity.id}
                            placeholder={entity.placeholder}
                            register={register(
                              `services[${serviceIdx}].${entity.name}`
                            )}
                          />
                        )}
                        {entity.type === "textArea" && (
                          <TextArea
                            name={`services[${serviceIdx}].${entity.name}`}
                            id={entity.id}
                            placeholder={entity.placeholder}
                            register={register(
                              `services[${serviceIdx}].${entity.name}`
                            )}
                          />
                        )}
                      </InputComponent>
                    );
                  })}

                  {/* stacks */}
                  <ServicesStacks
                    control={control}
                    register={register}
                    serviceIdx={serviceIdx}
                  />

                  {serviceIdx > 0 && (
                    <RemoveButton
                      className={"self-center mb-1"}
                      onClick={() => removeService(serviceIdx)}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
}

function TalkToUsSection() {
  const { register } = useFormContext();
  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Talk To Us Section" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        {talkToUs.map((field, index) => {
          return (
            <InputComponent key={index} className="">
              <Label htmlFor={field.id} label={field.label} />
              {field.type === "text" && (
                <InputField
                  type={field.type}
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
            </InputComponent>
          );
        })}
      </div>
    </Section>
  );
}

// Main component
function ServicePage() {
  const [isNew, setIsNew] = useState(true);
  const [response, setResponse] = useState(null);
  const [statusCode, setStatusCode] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);

  const methods = useForm({
    defaultValues: {
      ...heroTemplates,
      ...aboutTemplates,
      ...servicesTemplates,
      ...talkToUsTemplates,
    },
  });
  const loading = useSelector((state) => state.servicePage.loading);
  const error = useSelector((state) => state.servicePage.error);
  const serviceData = useSelector((state) => state.servicePage.data);

  const isInitialized = useRef(false);
  const { makeRequest: getServiceData } = useRequest("GET", {}, serviceSlice);
  const { makeRequest: updateServiceData } = useRequest(
    "PUT",
    {},
    serviceSlice
  );

  // GET FORM DATA
  useEffect(() => {
    const getData = async () => {
      setLoadingMessage("Getting data...");
      const [, initialStatusCode, initialResponse] = await getServiceData(
        "/service"
      );
      setResponse(initialResponse);
      setStatusCode(initialStatusCode);
      handleResponse(
        response,
        statusCode,
        error,
        serviceData,
        "Successfully fetched!"
      );
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!serviceData) {
      const transformedData = transformApiData(serviceData, serviceSectionMap);
      if (transformedData && Object.keys(transformedData).length > 0) {
        methods.reset(transformedData);
        setIsNew(false);
        isInitialized.current = true;
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceData]);

  // UPDATE FUNCTION
  const handleUpdate = async (data) => {
    const reversedTransformData = reverseTransformApiData(
      data,
      serviceSectionMap
    );
    if (!isNew) {
      setLoadingMessage("Updating");
      const [, initialStatusCode, initialResponse] = await updateServiceData(
        "/service",
        reversedTransformData
      );
      setResponse(initialResponse);
      setStatusCode(initialStatusCode);
    }
    const toastMessage = "Successfully updated";

    const toastResponse = handleResponse(
      response,
      statusCode,
      error,
      serviceData,
      toastMessage
    );
    if (toastResponse === "successful") return;
  };

  if (loading && loadingMessage === "Getting data...") {
    return <DashLoader message={loadingMessage} />;
  }
  return (
    <FormProvider {...methods}>
      <FormWrapper
        title="Services Page"
        onSubmit={methods.handleSubmit(handleUpdate)}
        loading={
          (loadingMessage === "Updating" && loading) ||
          methods.formState.isSubmitting
        }
      >
        <HeroSection />
        <AboutSection />
        <ServicesSection />
        <TalkToUsSection />
      </FormWrapper>
    </FormProvider>
  );
}

export default ServicePage;
