export const aboutHeroSection = [
  {
    type: "text",
    name: "heroTitle",
    id: "heroTitle",
    label: "Title",
    placeholder: "We are a creative ...",
  },
  {
    type: "text",
    name: "heroBody",
    id: "heroBody",
    label: "Body",
    placeholder: "We design & create ...",
  },
  {
    type: "textArea",
    name: "heroPara",
    id: "heroPara",
    label: "Description",
    placeholder: "Elevate your business...",
  },
  {
    type: "text",
    name: "heroBtn",
    id: "heroBtn",
    label: "Button",
    placeholder: "Contact Us",
  },
];

export const aboutHeroTemplates = {
  heroTitle: "",
  heroBody: "",
  heroPara: "",
  heroBtn: "",
};

export const storySection = [
  {
    type: "textArea",
    name: "story1",
    id: "story1",
    label: "Story 1",
    placeholder: "...",
  },
  {
    type: "textArea",
    name: "story2",
    id: "story2",
    label: "Story 2",
    placeholder: "...",
  },
  {
    type: "textArea",
    name: "story3",
    id: "story3",
    label: "Story 3",
    placeholder: "...",
  },
];

export const storyTemplates = {
  story1: "",
  story2: "",
  story3: "",
};

export const numberExpSection = [
  {
    type: "text",
    name: "experienceNumber",
    id: "experienceNumber",
    label: "Number",
    placeholder: "...",
  },
  {
    type: "text",
    name: "experienceText",
    id: "experienceText",
    label: "Text",
    placeholder: "...",
  },
];

export const numberExpTemplates = {
  experienceNumber: "",
  experienceText: "",
};

export const numberClientsSection = [
  {
    type: "text",
    name: "clientsNumber",
    id: "clientsNumber",
    label: "Number",
    placeholder: "...",
  },
  {
    type: "text",
    name: "clientsText",
    id: "clientsText",
    label: "Text",
    placeholder: "...",
  },
];

export const numberClientsTemplates = {
  clientsNumber: "",
  clientsText: "",
};

export const numberProjectsSection = [
  {
    type: "text",
    name: "projectsNumber",
    id: "projectsNumber",
    label: "Number",
    placeholder: "...",
  },
  {
    type: "text",
    name: "projectsText",
    id: "projectsText",
    label: "Text",
    placeholder: "...",
  },
];

export const numberProjectsTemplates = {
  projectsNumber: "",
  projectsText: "",
};

export const serviceSection = [
  {
    type: "text",
    name: "serviceTitle",
    id: "serviceTitle",
    label: "Title",
    placeholder: "..",
  },
  {
    type: "text",
    name: "serviceButton",
    id: "serviceButton",
    label: "Button",
    placeholder: "...",
  },
];

export const serviceTemplates = {
  serviceTitle: "",
  serviceButton: "",
};

export const sectionSection = [
  {
    type: "text",
    name: "sectionTitle",
    id: "sectionTitle",
    label: "Title",
    placeholder: "..",
  },
  {
    type: "text",
    name: "sectionButton",
    id: "sectionButton",
    label: "Contact Button",
    placeholder: "...",
  },
];

export const sectionTemplates = {
  sectionTitle: "",
  sectionButton: "",
};
