import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useRequest from "../../../../components/hook/use-request";
import { handleResponse } from "../../../../utils/handleResponse";
import { aboutSlice } from "../../../../redux/slice/about-slice";
import { replaceObjectKeys } from "../../../../utils/utilsFunction";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import ViewsCard from "../../../../components/dashboard/viewsCard";
import PropTypes from "prop-types";
import DashLoader from "../../../../components/global/dash-loader";

function HeroSection({ heroData }) {
  const newKeys = ["Title", "Body", "Paragraph", "Contact Button"];

  const newHeroData = replaceObjectKeys(heroData, newKeys);

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Hero Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center md:justify-items-start gap-4">
        {Object.entries(newHeroData).map(([element, desc]) => {
          return (
            <div key={element} className="flex flex-col gap-3 font-semibold">
              <h3>{element}</h3>
              <ViewsCard content={desc} />
            </div>
          );
        })}
      </div>
    </Section>
  );
}

HeroSection.propTypes = {
  heroData: PropTypes.object.isRequired,
};

function StorySection({ storyData }) {
  const newKeys = ["Story 1", "Story 2", "Story 3"];

  const newStoryData = replaceObjectKeys(storyData, newKeys);

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Story Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center md:justify-items-start gap-4">
        {Object.entries(newStoryData).map(([element, desc]) => {
          return (
            <div key={element} className="flex flex-col gap-3 font-semibold">
              <h3>{element}</h3>
              <ViewsCard content={desc} />
            </div>
          );
        })}
      </div>
    </Section>
  );
}

StorySection.propTypes = {
  storyData: PropTypes.object.isRequired,
};

function NumberSection({ numberData }) {
  const newKeys = ["Experience", "Clients", "Projects"];

  const newNumberData = replaceObjectKeys(numberData, newKeys);

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Number Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center md:justify-items-start gap-4">
        {Object.entries(newNumberData).map(([element, desc]) => {
          return (
            <div
              key={element}
              className="flex flex-col gap-3 flex-wrap font-semibold"
            >
              <h3 className="self-center">{element}</h3>
              <div className="flex gap-3 font-semibold">
                <ViewsCard
                  content={desc.number}
                  label={"Number"}
                  labelStyle="text-sm"
                />
                <ViewsCard
                  content={desc.text}
                  label={"Text"}
                  labelStyle="text-sm"
                />
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
}

NumberSection.propTypes = {
  numberData: PropTypes.object.isRequired,
};

function ServiceSection({ serviceData }) {
  const newKeys = ["Title", "Join Us Button"];

  const newSectionData = replaceObjectKeys(serviceData, newKeys);

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Service Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center md:justify-items-start gap-4">
        {Object.entries(newSectionData).map(([element, desc]) => {
          return (
            <div key={element} className="flex flex-col gap-3 font-semibold">
              <h3>{element}</h3>
              <ViewsCard content={desc} />
            </div>
          );
        })}
      </div>
    </Section>
  );
}
ServiceSection.propTypes = {
  sectionData: PropTypes.object.isRequired,
};
function TalkToUsSection({ sectionData }) {
  const newKeys = ["Title", "Talk To us Button"];

  const newSectionData = replaceObjectKeys(sectionData, newKeys);

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Talk To Us Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center md:justify-items-start gap-4">
        {Object.entries(newSectionData).map(([element, desc]) => {
          return (
            <div key={element} className="flex flex-col gap-3 font-semibold">
              <h3>{element}</h3>
              <ViewsCard content={desc} />
            </div>
          );
        })}
      </div>
    </Section>
  );
}
TalkToUsSection.propTypes = {
  sectionData: PropTypes.object.isRequired,
};

function AboutV() {
  const loading = useSelector((state) => state.aboutPage.loading);
  const error = useSelector((state) => state.aboutPage.error);
  const aboutData = useSelector((state) => state.aboutPage.data);

  const { makeRequest: getAboutData } = useRequest("GET", {}, aboutSlice);

  useEffect(() => {
    const getData = async () => {
      const [, statusCode, response] = await getAboutData("/about");

      handleResponse(
        response,
        statusCode,
        error,
        aboutData,
        "Successfully fetched!"
      );
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && !aboutData) return <DashLoader message="Loading..." />;

  return (
    <div className="flex flex-col gap-8 pt-6 pb-10">
      <h1 className="text-xl self-center md:text-xl font-semibold">
        About Page
      </h1>
      {aboutData ? (
        <div className="flex flex-col gap-8">
          <HeroSection heroData={aboutData?.hero} />
          <StorySection storyData={aboutData?.story} />
          <NumberSection numberData={aboutData?.number} />
          <ServiceSection serviceData={aboutData?.service} />
          <TalkToUsSection sectionData={aboutData?.section} />
        </div>
      ) : (
        <span>Fetching Data ...</span>
      )}
    </div>
  );
}

export default AboutV;
