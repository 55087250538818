import React from "react";
import PropTypes from "prop-types";
import Loader from "./loader-page";

function GeneralButton({
  content = "Add Content",
  className = "",
  isLoading = false,
  type = "submit",
}) {
  return (
    <button
      className={`bg-black950 text-white rounded-md px-4 py-2 border text-sm font-medium ${className}`}
      type={type}
    >
      {isLoading ? <Loader /> : <span className="">{content}</span>}
    </button>
  );
}

GeneralButton.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  type: PropTypes.string,
};

export default GeneralButton;
