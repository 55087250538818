import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import DashLoader from "../../../../components/global/dash-loader";
import {
  reverseTransformApiData,
  transformApiData,
} from "../../../../utils/transformData";
import { debounce, isEqual } from "lodash";
import { handleResponse } from "../../../../utils/handleResponse";
import useRequest from "../../../../components/hook/use-request";
import { useSelector } from "react-redux";
import { aboutSlice } from "../../../../redux/slice/about-slice";
import {
  aboutHeroSection,
  aboutHeroTemplates,
  numberClientsSection,
  numberClientsTemplates,
  numberExpSection,
  numberExpTemplates,
  numberProjectsSection,
  numberProjectsTemplates,
  sectionSection,
  sectionTemplates,
  serviceSection,
  serviceTemplates,
  storySection,
  storyTemplates,
} from "../../../../utils/dashboardFormData/otherPages/aboutPageData";
import { aboutSectionMap } from "../../../../utils/sectionMapData";
import TextArea from "../../../../components/dashboard/textArea";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import InputComponent from "../../../../components/dashboard/inputComponent";
import Label from "../../../../components/dashboard/label";
import InputField from "../../../../components/dashboard/inputField";
import FormWrapper from "../../../../components/dashboard/formWrapper";

function HeroSection() {
  const { register } = useFormContext();

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Hero Section" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        {aboutHeroSection.map((field, index) => {
          return (
            <InputComponent key={index} className="">
              <Label htmlFor={field.id} label={field.label} />
              {field.type === "text" && (
                <InputField
                  type={field.type}
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
              {field.type === "textArea" && (
                <TextArea
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
            </InputComponent>
          );
        })}
      </div>
    </Section>
  );
}

function StorySection() {
  const { register } = useFormContext();
  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Story Section" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        {storySection.map((field, index) => {
          return (
            <InputComponent key={index} className="">
              <Label htmlFor={field.id} label={field.label} />
              {field.type === "textArea" && (
                <TextArea
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
            </InputComponent>
          );
        })}
      </div>
    </Section>
  );
}

function NumberSection() {
  const { register } = useFormContext();
  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Number Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        {/* Experience Section */}
        <div className="flex flex-col gap-3">
          <SectionHeading content="Experience Section" className="text-sm" />
          <div className="flex justify-between gap-2 flex-wrap">
            {numberExpSection.map((field, index) => {
              return (
                <InputComponent key={index} className="">
                  <Label htmlFor={field.id} label={field.label} />
                  {field.type === "text" && (
                    <InputField
                      type={field.type}
                      name={field.name}
                      id={field.id}
                      placeholder={field.placeholder}
                      register={register(field.name)}
                    />
                  )}
                </InputComponent>
              );
            })}
          </div>
        </div>

        {/* Clients Section */}
        <div className="flex flex-col gap-3">
          <SectionHeading content="Clients Section" className="text-sm" />{" "}
          <div className="flex justify-between gap-2 flex-wrap">
            {numberClientsSection.map((field, index) => {
              return (
                <InputComponent key={index} className="">
                  <Label htmlFor={field.id} label={field.label} />
                  {field.type === "text" && (
                    <InputField
                      type={field.type}
                      name={field.name}
                      id={field.id}
                      placeholder={field.placeholder}
                      register={register(field.name)}
                    />
                  )}
                </InputComponent>
              );
            })}
          </div>
        </div>

        {/* Projects Section */}
        <div className="flex flex-col gap-3">
          <SectionHeading content="Projects Section" className="text-sm" />
          <div className="flex justify-between gap-2 flex-wrap">
            {numberProjectsSection.map((field, index) => {
              return (
                <InputComponent key={index} className="">
                  <Label htmlFor={field.id} label={field.label} />
                  {field.type === "text" && (
                    <InputField
                      type={field.type}
                      name={field.name}
                      id={field.id}
                      placeholder={field.placeholder}
                      register={register(field.name)}
                    />
                  )}
                </InputComponent>
              );
            })}
          </div>
        </div>
      </div>
    </Section>
  );
}

function ServiceSection() {
  const { register } = useFormContext();

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Service Section" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        {serviceSection.map((field, index) => {
          return (
            <InputComponent key={index} className="">
              <Label htmlFor={field.id} label={field.label} />
              {field.type === "text" && (
                <InputField
                  type={field.type}
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
            </InputComponent>
          );
        })}
      </div>
    </Section>
  );
}

function SectionAbout() {
  const { register } = useFormContext();

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="About Section" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        {sectionSection.map((field, index) => {
          return (
            <InputComponent key={index} className="">
              <Label htmlFor={field.id} label={field.label} />
              {field.type === "text" && (
                <InputField
                  type={field.type}
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
            </InputComponent>
          );
        })}
      </div>
    </Section>
  );
}

function AboutPage() {
  const [isNew, setIsNew] = useState(true);
  const [response, setResponse] = useState(null);
  const [statusCode, setStatusCode] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);

  const methods = useForm({
    defaultValues: {
      ...aboutHeroTemplates,
      ...storyTemplates,
      ...numberExpTemplates,
      ...numberClientsTemplates,
      ...numberProjectsTemplates,
      ...serviceTemplates,
      ...sectionTemplates,
    },
  });
  const loading = useSelector((state) => state.aboutPage.loading);
  const error = useSelector((state) => state.aboutPage.error);
  const aboutData = useSelector((state) => state.aboutPage.data);

  const isInitialized = useRef(false);

  const { makeRequest: getAboutData } = useRequest("GET", {}, aboutSlice);
  const { makeRequest: updateAboutData } = useRequest("PUT", {}, aboutSlice);

  // GET FORM DATA
  useEffect(() => {
    const getData = async () => {
      setLoadingMessage("Getting data...");
      const [, initialStatusCode, initialResponse] = await getAboutData(
        "/about"
      );
      setResponse(initialResponse);
      setStatusCode(initialStatusCode);
      handleResponse(
        response,
        statusCode,
        error,
        aboutData,
        "Successfully fetched!"
      );
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!aboutData) {
      const transformedData = transformApiData(aboutData, aboutSectionMap);

      if (transformedData && Object.keys(transformedData).length > 0) {
        methods.reset(transformedData);
        setIsNew(false);
        isInitialized.current = true;
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aboutData]);

  // CREATE AND UPDATE FUNCTION
  const handleUpdate = async (data) => {
    const reversedTransformData = reverseTransformApiData(
      data,
      aboutSectionMap
    );
    if (!isNew) {
      setLoadingMessage("Updating");
      const [, initialStatusCode, initialResponse] = await updateAboutData(
        "/about",
        reversedTransformData
      );
      setResponse(initialResponse);
      setStatusCode(initialStatusCode);
    }
    const toastMessage = "Successfully updated";

    const toastResponse = handleResponse(
      response,
      statusCode,
      error,
      aboutData,
      toastMessage
    );
    if (toastResponse === "successful") return;
  };

  if (loading && loadingMessage === "Getting data...") {
    return <DashLoader message={loadingMessage} />;
  }
  return (
    <FormProvider {...methods}>
      <FormWrapper
        title="About Page"
        onSubmit={methods.handleSubmit(handleUpdate)}
        loading={
          (loadingMessage === "Updating" && loading) ||
          methods.formState.isSubmitting
        }
      >
        <HeroSection />
        <StorySection />
        <NumberSection />
        <ServiceSection />
        <SectionAbout />
      </FormWrapper>
    </FormProvider>
  );
}

export default AboutPage;
