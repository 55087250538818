export const heroSection = [
  {
    type: "text",
    name: "heroTitle",
    id: "heroTitle",
    label: "Title",
    placeholder: "We are a creative ...",
  },
  {
    type: "text",
    name: "heroBody",
    id: "heroBody",
    label: "Body",
    placeholder: "We design & create ...",
  },
  {
    type: "textArea",
    name: "heroPara",
    id: "heroPara",
    label: "Paragraph",
    placeholder: "Elevate your business...",
  },
];

export const heroTemplates = {
  heroTitle: "",
  heroBody: "",
  heroPara: "",
};

export const aboutFieldArr = [
  {
    type: "text",
    name: "aboutBody",
    id: "aboutBody",
    label: "Body",
    placeholder: "...",
  },
  {
    type: "textArea",
    name: "aboutPara",
    id: "aboutPara",
    label: "Paragraph",
    placeholder: "We ...",
  },
];

export const aboutTemplates = {
  about: [
    {
      aboutBody: "",
      aboutPara: "",
    },
  ],
};

export const servicesFieldArr = [
  {
    type: "text",
    name: "servicesTitle",
    id: "servicesTitle",
    label: "Service Title",
    placeholder: "...",
  },
  {
    type: "textArea",
    name: "servicesBody",
    id: "servicesBody",
    label: "Service Body",
    placeholder: "Enim odio ut nulla malesuada...",
  },
];

export const servicesTemplates = {
  services: [
    {
      servicesTitle: "",
      servicesBody: "",
      stacks: [""],
    },
  ],
};

export const talkToUs = [
  {
    type: "text",
    name: "talkToUsTitle",
    id: "talkToUsTitle",
    label: "Title",
    placeholder: "..",
  },
  {
    type: "text",
    name: "talkToUsButton",
    id: "talkToUsButton",
    label: "Contact Button",
    placeholder: "...",
  },
];

export const talkToUsTemplates = {
  talkToUsTitle: "",
  talkToUsButton: "",
};
