import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Home from "../pages/landing-page";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ResetPassword from "../pages/auth/ResetPassword";
import NewPassword from "../pages/auth/NewPassword";
import InviteUser from "../pages/dashboard/InviteUser";
import NotFound from "../pages/NotFound";
import Dashboard from "../pages/dashboard";
import DashSettings from "../pages/dashboard/DashSettings";
import Layout from "../components/dashboard/Layout";
import HomePage from "../pages/dashboard/projects/static/homePage";
import HomeV from "../pages/dashboard/projects/views/homeV";
import Test from "../components/global/test";
import ProtectedRoute from "../components/protectedRoutes/protectedRoute";
import ProjectV from "../pages/dashboard/projects/views/projectV";
import BlogV from "../pages/dashboard/projects/blogs/blogV";
import ServiceV from "../pages/dashboard/projects/views/serviceV";
import ProjectPage from "../pages/dashboard/projects/static/projectPage";
import BlogPage from "../pages/dashboard/projects/blogs/blogPage";
import ServicePage from "../pages/dashboard/projects/static/servicePage";
import CareersV from "../pages/dashboard/projects/views/careersV";
import CareersPage from "../pages/dashboard/projects/static/careersPage";
import RolesV from "../pages/dashboard/projects/views/roleV";
import RolePage from "../pages/dashboard/projects/static/rolePage";
import AboutPage from "../pages/dashboard/projects/static/aboutPage";
import PartnersPage from "../pages/dashboard/projects/static/partnersPage";
import PartnersV from "../pages/dashboard/projects/views/partnersV";
import AboutV from "../pages/dashboard/projects/views/aboutV";

const routes = (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/test" element={<Test />} />

    {/* Authentication */}
    <Route path="/auth">
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="new-password" element={<NewPassword />} />
    </Route>

    {/* Dashboard */}
    <Route
      path="/dashboard"
      element={
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      }
    >
      <Route index element={<Dashboard />} />
      <Route path="settings" element={<DashSettings />} />
      <Route path="invite-user" element={<InviteUser />} />
      {/* Project */}
      <Route path="projects" element={<Outlet />}>
        <Route index element={<Navigate to="static" />} />

        {/* Static */}
        <Route path="static" element={<Outlet />}>
          <Route index element={<Navigate to="home" />} />
          <Route path="home" element={<HomePage />} />
          <Route path="project" element={<ProjectPage />} />
          <Route path="service" element={<ServicePage />} />
          <Route path="career" element={<CareersPage />} />
          <Route path="role" element={<RolePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="partner" element={<PartnersPage />} />
        </Route>

        {/* Blogs */}
        <Route path="blogs" element={<Outlet />}>
          <Route index element={<Navigate to="blog" />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="view" element={<BlogV />} />
        </Route>

        {/* Views */}
        <Route path="views" element={<Outlet />}>
          <Route index element={<Navigate to="home" />} />
          <Route path="home" element={<HomeV />} />
          <Route path="project" element={<ProjectV />} />
          <Route path="service" element={<ServiceV />} />
          <Route path="career" element={<CareersV />} />
          <Route path="career/role" element={<RolesV />} />
          <Route path="about" element={<AboutV />} />
          <Route path="partner" element={<PartnersV />} />
        </Route>
      </Route>
    </Route>

    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default routes;
