// This maps the API data keys to form field labels.
// It is used to transform the raw API data into a structure
// that matches your form fields and labels. Nested arrays and
// objects are handled by specifying mappings for individual fields.

// Home Page
export const homeSectionMap = {
  heroTitle: "hero.title",
  heroBody: "hero.body",
  heroPara: "hero.paragraph",
  heroBtn: "hero.contactBtn",

  aboutBody: "about.body",
  aboutSubBody: "about.subBody",
  aboutPara: "about.paragraph",
  aboutButton: "about.learnBtn",

  servicesMainTitle: "services.body",
  servicesPara: "services.paragraph",
  listOfServices: {
    section: "services.services",
    arrayField: true,
    itemMap: {
      name: "title",
      desc: "body",
    },
  },

  talkToUsTitle: "section.title",
  talkToUsButton: "section.contactBtn",
};

// Project page
export const projectSectionMap = {
  projectBody: "body", // maps top-level "body" field
  projectPara: "paragraph", // maps top-level "paragraph" field

  // Projects array (root-level array of objects)
  projects: {
    arrayField: true,
    section: "projects",
    isPrimitive: false,
    itemMap: {
      title: "title", // Project title
      paragraph: "paragraph", // Project paragraph
      image: "image", // Project image

      // Overview fields (nested object inside project)
      overview: {
        section: "overview",
        itemMap: {
          client: "client",
          service: "service",
          duration: "duration",
          deliverable: "deliverable",
        },
      },

      summaryOne: {
        section: "summaryOne",
        itemMap: {
          paragraph: "paragraph",
          image: "image",
          lists: {
            arrayField: true,
            section: "lists",
            itemMap: {
              items: "items",
            },
          },
        },
      },
      summaryTwo: {
        section: "summaryTwo",
        itemMap: {
          title: "title",
          paragraph: "paragraph",
          image: "image",
          lists: {
            arrayField: true,
            section: "lists",
            itemMap: {
              items: "items",
            },
          },
        },
      },

      // Keywords (array of strings inside project)
      keywords: {
        arrayField: true,
        section: "keywords", // Refers to the array of keywords inside each project
        isPrimitive: true, // Array of strings (keywords)
      },

      // Reviews (array of objects inside project)
      reviews: {
        arrayField: true,
        section: "reviews", // Refers to the reviews array inside each project
        isPrimitive: false,
        itemMap: {
          name: "name", // Reviewer's name
          comment: "comment", // Reviewer's comment
          image: "image", // Reviewer's image
        },
      },
    },
  },
};

// Blog Page (data coming as an array of object)
export const blogSectionMap = {
  blogs: {
    arrayField: true,
    isPrimitive: false, // The items are objects, not primitive values
    section: "", // Empty string means it's not under any parent object
    itemMap: {
      title: "title",
      url: "url",
      author: "author",
      readTime: "readTime",
      image: "image",
      id: "_id",
    },
  },
};

// Services Page
export const serviceSectionMap = {
  heroTitle: "hero.title",
  heroBody: "hero.body",
  heroPara: "hero.paragraph",
  about: {
    arrayField: true,
    section: "about",
    itemMap: {
      aboutBody: "body",
      aboutPara: "paragraph",
    },
  },
  services: {
    arrayField: true,
    section: "services",
    itemMap: {
      servicesTitle: "title",
      servicesBody: "body",
      stacks: {
        arrayField: true,
        isPrimitive: true,
        section: "stacks",
      },
    },
  },
  talkToUsTitle: "section.title",
  talkToUsButton: "section.contactBtn",
};

// Career Page
export const careerSectionMap = {
  heroTitle: "hero.title",
  heroBody: "hero.body",
  heroPara: "hero.paragraph",
  heroBtn: "hero.btn",

  aboutCareer: {
    section: "about",
    arrayField: true,
    isPrimitive: false,
    itemMap: {
      title: "title",
      body: "body",
    },
  },
  joinUsTitle: "sectionOne.title",
  joinUsButton: "sectionOne.contactBtn",

  talkToUsTitle: "sectionTwo.title",
  talkToUsButton: "sectionTwo.contactBtn",
};

// Role Page (data coming as an array of object)
export const roleSectionMap = {
  roles: {
    arrayField: true,
    isPrimitive: false, // The items are objects, not primitive values
    section: "", // Empty string means it's not under any parent object
    itemMap: {
      title: "title",
      body: "body",
      paragraph: "paragraph",
      btn: "btn",
      details: "details",
      link: "link",
      id: "_id",
    },
  },
};

//About Page
export const aboutSectionMap = {
  heroTitle: "hero.title",
  heroBody: "hero.body",
  heroPara: "hero.paragraph",
  heroBtn: "hero.btn",
  story1: "story.story1",
  story2: "story.story2",
  story3: "story.story3",
  experienceNumber: "number.experience.number",
  experienceText: "number.experience.text",
  clientsNumber: "number.clients.number",
  clientsText: "number.clients.text",
  projectsNumber: "number.projects.number",
  projectsText: "number.projects.text",
  serviceTitle: "service.title",
  serviceButton: "service.joinBtn",
  sectionTitle: "section.title",
  sectionButton: "section.contactBtn",
};

// Partners Page
export const partnerSectionMap = {
  heroTitle: "hero.title",
  heroBody: "hero.body",
  heroPara: "hero.paragraph",
  heroBtn: "hero.btn",
  benefitTitle: "benefit.title",
  benefits: {
    section: "benefit.benefits",
    arrayField: true,
    itemMap: {
      title: "title",
      body: "body",
    },
  },
  communityTitle: "community.title",
  communityButton: "community.joinBtn",

  sectionTitle: "section.title",
  sectionButton: "section.contactBtn",
};
