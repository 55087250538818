import React from "react";
import SearchBar from "../../components/dashboard/SearchBar";
import ProjectCard from "../../components/dashboard/ProjectCard";

function Dashboard() {
  return (
    <div className="w-full h-full flex flex-col gap-8">
      <div className="flex gap-5 flex-wrap md:justify-between w-full">
        {/* <DashButton
          content="Add New"
          icon={<Icons.Plus className="w-4 h-4 self-center" />}
          className=""
        /> */}
        <SearchBar className="self-start" placeholder={"Search by keyword"} />
      </div>

      <main className="flex flex-col gap-2">
        <h1 className="text-base md:text-xl font-medium">Projects</h1>
        <section className="flex items-center flex-row flex-wrap gap-8 pt-3 pb-4 border-t border-slate-300">
          <ProjectCard
            title="Pinnacle"
            description="Padding website"
            to="/dashboard/projects/static"
          />
          <ProjectCard title="Ronaldo" description="An Officing website" />
        </section>
      </main>
    </div>
  );
}

export default Dashboard;
