import React from "react";
import PropTypes from "prop-types";

function Logo({ className }) {
  return (
    <div className={`w-24 h-7 ${className}`}>
      <img
        src="/images/padding_logo.png"
        alt="Padding Logo"
        className={`w-full h-full bg-cover`}
      />
    </div>
  );
}

Logo.propType = {
  className: PropTypes.string,
};

export default Logo;
