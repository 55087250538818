import React, { useEffect, useRef, useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useSelector } from "react-redux";
import useRequest from "../../../../components/hook/use-request";
import { handleResponse } from "../../../../utils/handleResponse";
import {
  reverseTransformApiData,
  transformApiData,
} from "../../../../utils/transformData";
import { debounce, isEqual } from "lodash";
import DashLoader from "../../../../components/global/dash-loader";
import {
  communitySection,
  communityTemplates,
  partnerBenefitsFieldArr,
  partnerBenefitsTemplates,
  partnerHeroSection,
  partnerHeroTemplates,
  sectionPartner,
  sectionPartnerTemplates,
} from "../../../../utils/dashboardFormData/otherPages/partnersPageData";
import { partnerSlice } from "../../../../redux/slice/partner-slice";
import { partnerSectionMap } from "../../../../utils/sectionMapData";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import InputComponent from "../../../../components/dashboard/inputComponent";
import Label from "../../../../components/dashboard/label";
import InputField from "../../../../components/dashboard/inputField";
import TextArea from "../../../../components/dashboard/textArea";
import AddButton from "../../../../components/dashboard/addButton";
import RemoveButton from "../../../../components/dashboard/removeButton";
import FormWrapper from "../../../../components/dashboard/formWrapper";

function HeroSection() {
  const { register } = useFormContext();

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Hero Section" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        {partnerHeroSection.map((field, index) => {
          return (
            <InputComponent key={index} className="">
              <Label htmlFor={field.id} label={field.label} />
              {field.type === "text" && (
                <InputField
                  type={field.type}
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
              {field.type === "textArea" && (
                <TextArea
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
            </InputComponent>
          );
        })}
      </div>
    </Section>
  );
}

function BenefitSection() {
  const { register, control } = useFormContext();
  const {
    fields: benefitsFields,
    append: appendBenefit,
    remove: removeBenefit,
  } = useFieldArray({
    control,
    name: "benefits",
  });
  return (
    <Section className="flex flex-col gap-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <InputComponent className="">
          <Label htmlFor={"benefitTitle"} label={"Benefit Title"} />
          <InputField
            type={"text"}
            name={`benefitTitle`}
            id={"benefitTitle"}
            placeholder={"..."}
            register={register(`benefitTitle`)}
          />
        </InputComponent>
        {/* Field array section */}
        <div className="md:col-span-2">
          <AddButton
            onClick={() =>
              appendBenefit({
                title: "",
                body: "",
              })
            }
            content="Add Benefit"
            className={"gap-2"}
          />
        </div>

        {/* Field Array */}
        <div className="w-full col-span-1 md:col-span-2 flex flex-col gap-2">
          {benefitsFields.map((benefit, benefitIdx) => {
            return (
              <div
                key={benefit.id}
                className="flex flex-col gap-4 w-full self-center md:self-start"
              >
                <SectionHeading
                  content={`Benefit ${benefitIdx + 1}`}
                  className="bg-slate-700 text-white"
                />
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6 ">
                  {partnerBenefitsFieldArr.map((entity, entityIdx) => {
                    return (
                      <InputComponent key={entityIdx} className="">
                        <Label htmlFor={entity.id} label={entity.label} />
                        {entity.type === "text" && (
                          <InputField
                            type={entity.type}
                            name={`benefits[${benefitIdx}].${entity.name}`}
                            id={entity.id}
                            placeholder={entity.placeholder}
                            register={register(
                              `benefits[${benefitIdx}].${entity.name}`
                            )}
                          />
                        )}
                        {entity.type === "textArea" && (
                          <TextArea
                            name={`benefits[${benefitIdx}].${entity.name}`}
                            id={entity.id}
                            placeholder={entity.placeholder}
                            register={register(
                              `benefits[${benefitIdx}].${entity.name}`
                            )}
                          />
                        )}
                      </InputComponent>
                    );
                  })}

                  {benefitIdx > 0 && (
                    <RemoveButton
                      className={"self-center mb-1"}
                      onClick={() => removeBenefit(benefitIdx)}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
}

function CommunitySection() {
  const { register } = useFormContext();

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Community Section" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        {communitySection.map((field, index) => {
          return (
            <InputComponent key={index} className="">
              <Label htmlFor={field.id} label={field.label} />
              {field.type === "text" && (
                <InputField
                  type={field.type}
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
            </InputComponent>
          );
        })}
      </div>
    </Section>
  );
}

function SectionPartner() {
  const { register } = useFormContext();

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Section" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        {sectionPartner.map((field, index) => {
          return (
            <InputComponent key={index} className="">
              <Label htmlFor={field.id} label={field.label} />
              {field.type === "text" && (
                <InputField
                  type={field.type}
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
            </InputComponent>
          );
        })}
      </div>
    </Section>
  );
}

function PartnersPage() {
  const [isNew, setIsNew] = useState(true);
  const [response, setResponse] = useState(null);
  const [statusCode, setStatusCode] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);

  const methods = useForm({
    defaultValues: {
      ...partnerHeroTemplates,
      benefitTitle: "",
      ...partnerBenefitsTemplates,
      ...communityTemplates,
      ...sectionPartnerTemplates,
    },
  });
  const loading = useSelector((state) => state.partnerPage.loading);
  const error = useSelector((state) => state.partnerPage.error);
  const partnerData = useSelector((state) => state.partnerPage.data);

  const isInitialized = useRef(false);

  const { makeRequest: getPartnerData } = useRequest("GET", {}, partnerSlice);
  const { makeRequest: updatePartnerData } = useRequest(
    "PUT",
    {},
    partnerSlice
  );

  // GET FORM DATA
  useEffect(() => {
    const getData = async () => {
      setLoadingMessage("Getting data...");
      const [, initialStatusCode, initialResponse] = await getPartnerData(
        "/partner"
      );
      setResponse(initialResponse);
      setStatusCode(initialStatusCode);
      handleResponse(
        response,
        statusCode,
        error,
        partnerData,
        "Successfully fetched!"
      );
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!partnerData) {
      const transformedData = transformApiData(partnerData, partnerSectionMap);

      if (transformedData && Object.keys(transformedData).length > 0) {
        methods.reset(transformedData);
        setIsNew(false);
        isInitialized.current = true;
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerData]);

  // CREATE AND UPDATE FUNCTION
  const handleUpdate = async (data) => {
    const reversedTransformData = reverseTransformApiData(
      data,
      partnerSectionMap
    );
    if (!isNew) {
      setLoadingMessage("Updating");
      const [, initialStatusCode, initialResponse] = await updatePartnerData(
        "/partner",
        reversedTransformData
      );
      setResponse(initialResponse);
      setStatusCode(initialStatusCode);
    }
    const toastMessage = "Successfully updated";

    const toastResponse = handleResponse(
      response,
      statusCode,
      error,
      partnerData,
      toastMessage
    );
    if (toastResponse === "successful") return;
  };

  if (loading && loadingMessage === "Getting data...") {
    return <DashLoader message={loadingMessage} />;
  }
  return (
    <FormProvider {...methods}>
      <FormWrapper
        title="Partners Page"
        onSubmit={methods.handleSubmit(handleUpdate)}
        loading={
          (loadingMessage === "Updating" && loading) ||
          methods.formState.isSubmitting
        }
      >
        <HeroSection />
        <BenefitSection />
        <CommunitySection />
        <SectionPartner />
      </FormWrapper>
    </FormProvider>
  );
}

export default PartnersPage;
