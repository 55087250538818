import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Icons } from "../../components/global/icon";
import Logo from "../../components/global/logo";
import GeneralButton from "../../components/global/general-button";

function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, formState } = useForm();
  const { errors } = formState;

  const navigate = useNavigate();

  const resetPassword = async (data) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      navigate("/new-password");
    }, 3000);

    console.log("Form submitted!", data);
  };

  return (
    <div className="flex overflow-hidden h-screen">
      {/* Image section */}
      <div className="hidden md:block lg:w-[50vw] md:h-screen">
        <img
          src="/images/cms_image.webp"
          alt="Background"
          className="w-full h-full object-cover"
        />
      </div>

      <main className="flex justify-center items-center px-10 lg:px-0 w-full lg:w-[50vw] h-full">
        <div className="flex flex-col gap-9 w-full lg:w-[25rem] max-w-[20rem] lg:max-w-[25rem]">
          {/* Header */}
          <Logo className={"self-center"} />
          <div className="flex flex-col gap-1 items-center">
            <h1 className="font-semibold text-xl lg:text-2xl">
              Reset Password
            </h1>
            <p className="text-xs">
              Enter your email and we'll send you an email to reset your
              password
            </p>
          </div>

          {/* Form section */}
          <form
            onSubmit={handleSubmit(resetPassword)}
            className="flex flex-col gap-7"
          >
            <div className="flex flex-col gap-2 w-full">
              <label htmlFor="email" className="text-sm font-medium">
                Email
              </label>
              <input
                type="email"
                {...register("email", {
                  required: {
                    value: true,
                    message: `email is required`,
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Invalid email format",
                  },
                })}
                placeholder="johndoe@gmail.com"
                id="email"
                className="border border-slate-400 px-4 py-2 focus:outline-none focus:border-green-300 rounded-md"
              />
              {errors.email && (
                <p className="text-red-700 text-xs">{errors.email?.message}</p>
              )}
            </div>

            <GeneralButton
              content="Reset Password"
              className="w-full"
              isLoading={isLoading}
            />

            <Link className="flex gap-2 self-center text-sm" to={"/"}>
              <Icons.ArrowLeft width={18} height={18} className="self-center" />
              <span>Back home</span>
            </Link>
          </form>
        </div>
      </main>
    </div>
  );
}

export default ResetPassword;
