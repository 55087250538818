import React from "react";
import { Icons } from "../global/icon";
import PropTypes from "prop-types";

function AddButton({ onClick, content = "", className }) {
  return (
    <button
      className={`px-2 py-2 w-fit h-fit bg-black950 flex rounded-md justify-center text-white ${className}`}
      onClick={onClick}
      type="button"
    >
      <Icons.Plus className="w-5 h-5 self-center" strokeWidth={2} />
      <span>{content}</span>
    </button>
  );
}

AddButton.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  onClick: PropTypes.func,
};

export default AddButton;
