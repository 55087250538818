// TRANSFORM API DATA SECTION
export function transformApiData(data, sectionMap) {
  const transformedData = {};

  for (const mapKey in sectionMap) {
    const mapping = sectionMap[mapKey];

    if (typeof mapping === "string") {
      transformedData[mapKey] = transformSimpleField(data, mapping);
    } else if (mapping.arrayField) {
      let arrayData;

      // If the section is empty, the array is directly in the root data
      if (mapping.section === "") {
        arrayData = data; // data itself is the array
      } else {
        arrayData = getNestedField(data, mapping.section); // get array from a nested object
      }
      transformedData[mapKey] = transformArrayField(arrayData, mapping);
    } else if (typeof mapping === "object" && !mapping.arrayField) {
      // Handle nested objects
      const nestedData = getNestedField(data, mapping.section);
      transformedData[mapKey] = transformApiData(nestedData, mapping.itemMap); // Recursively transform nested objects
    }
  }

  return transformedData;
}

// Helper function to get nested fields
function getNestedField(data, path) {
  const parts = path.split(".");
  let result = data;

  for (const part of parts) {
    result = result ? result[part] : undefined;
  }

  return result;
}

function transformSimpleField(data, fieldPath) {
  const value = getNestedField(data, fieldPath);
  return value || ""; // Default to empty string if not found
}

function transformArrayField(arrayData, mapping) {
  if (!Array.isArray(arrayData)) {
    return []; // Return an empty array if it's not an array
  }

  return arrayData.map((item) => {
    if (mapping.itemMap) {
      // Recursively transform each object in the array
      return transformApiData(item, mapping.itemMap);
    }
    return item; // For simple arrays
  });
}

// REVERSE TRANSFORM API DATA SECTION

export function reverseTransformApiData(transformedData, sectionMap) {
  const originalData = {};

  for (const mapKey in sectionMap) {
    const mapping = sectionMap[mapKey];

    if (typeof mapping === "string") {
      // Simple field reversal
      setNestedField(
        originalData,
        mapping,
        reverseSimpleField(transformedData[mapKey])
      );
    } else if (mapping.arrayField) {
      // Array field reversal
      const reversedArray = reverseArrayField(transformedData[mapKey], mapping);

      if (mapping.section === "") {
        // If no section is defined, store the array in the root of original data
        Object.assign(originalData, reversedArray);
      } else {
        setNestedField(originalData, mapping.section, reversedArray);
      }
    } else if (typeof mapping === "object" && !mapping.arrayField) {
      // Nested object reversal
      const nestedReversedData = reverseTransformApiData(
        transformedData[mapKey],
        mapping.itemMap
      );
      setNestedField(originalData, mapping.section, nestedReversedData);
    }
  }

  return originalData;
}

// Helper function to set nested fields
function setNestedField(data, path, value) {
  const parts = path.split(".");
  let current = data;

  for (let i = 0; i < parts.length - 1; i++) {
    if (!current[parts[i]]) {
      current[parts[i]] = {}; // Create intermediate objects if they don't exist
    }
    current = current[parts[i]];
  }

  current[parts[parts.length - 1]] = value;
}

// Reverse simple fields
function reverseSimpleField(value) {
  return value || ""; // Default to empty string if no value
}

// Reverse array fields
function reverseArrayField(arrayData, mapping) {
  if (!Array.isArray(arrayData)) {
    return []; // Return empty array if it's not an array
  }

  return arrayData.map((item) => {
    if (mapping.itemMap) {
      // Recursively reverse each object in the array
      return reverseTransformApiData(item, mapping.itemMap);
    }
    return item; // For simple arrays, return the item as is
  });
}
