import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  message: null,
  loading: false,
  error: null,
};

export const partnerSlice = createSlice({
  name: "partnerPage",
  initialState,
  reducers: {
    startRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
      state.message = action.payload.message;
    },
    requestFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message || "An error occurred";
    },
  },
});

export const { startRequest, requestSuccess, requestFailure } =
  partnerSlice.actions;
export default partnerSlice.reducer;
