import React, { useEffect } from "react";
import ViewsCard from "../../../../components/dashboard/viewsCard";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import PropTypes from "prop-types";
import { replaceObjectKeys } from "../../../../utils/utilsFunction";
import { useSelector } from "react-redux";
import useRequest from "../../../../components/hook/use-request";
import { homeSlice } from "../../../../redux/slice/home-slice";
import DashLoader from "../../../../components/global/dash-loader";
import { handleResponse } from "../../../../utils/handleResponse";

// Subsections
function HeroSection({ heroData }) {
  const newKeys = ["Label", "Title", "Paragraph", "Contact Button"];

  const newHeroData = replaceObjectKeys(heroData, newKeys);
  // console.log("Rendered hero");

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Hero Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center md:justify-items-start gap-4">
        {Object.entries(newHeroData).map(([element, desc]) => {
          return (
            <div key={element} className="flex flex-col gap-3 font-semibold">
              <h3>{element}</h3>
              <ViewsCard content={desc} />
            </div>
          );
        })}
      </div>
    </Section>
  );
}

HeroSection.propTypes = {
  heroData: PropTypes.object.isRequired,
};

function AboutSection({ aboutData }) {
  const newKeys = ["Body", "Sub-Body", "Paragraph", "Learn More Button"];

  const newSectionData = replaceObjectKeys(aboutData, newKeys);
  // console.log("Rendered hero");

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Hero Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center md:justify-items-start gap-4">
        {Object.entries(newSectionData).map(([element, desc]) => {
          return (
            <div key={element} className="flex flex-col gap-3 font-semibold">
              <h3>{element}</h3>
              <ViewsCard content={desc} />
            </div>
          );
        })}
      </div>
    </Section>
  );
}
AboutSection.propTypes = {
  aboutData: PropTypes.object.isRequired,
};

function ServicesSection({ servicesData }) {
  const newKeys = ["Body", "Paragraph", "Services"];

  const newServicesData = replaceObjectKeys(servicesData, newKeys);

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Services Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8">
        {Object.entries(newServicesData).map(([element, desc]) => {
          if (element === "Services") {
            return (
              <div
                key={element}
                className="flex flex-col gap-3 font-semibold col-span-3 w-full"
              >
                <SectionHeading content="Services" className="self-center" />
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3">
                  {desc?.map((each, idx) => {
                    return (
                      <div key={idx} className="flex flex-col gap-3">
                        <h3>{each.title}</h3>
                        <div className="flex gap-3">
                          <ViewsCard
                            content={each?.title}
                            className="self-start"
                          />
                          <ViewsCard content={each?.body} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          } else {
            return (
              <div key={element} className="flex flex-col gap-3 font-semibold">
                <h3>{element}</h3>
                <ViewsCard content={desc} />
              </div>
            );
          }
        })}
      </div>
    </Section>
  );
}

ServicesSection.propTypes = {
  servicesData: PropTypes.object.isRequired,
};

function TalkToUsSection({ sectionData }) {
  const newKeys = ["Title", "Talk To us Button"];

  const newSectionData = replaceObjectKeys(sectionData, newKeys);
  // console.log("Rendered hero");

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Talk To Us Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center md:justify-items-start gap-4">
        {Object.entries(newSectionData).map(([element, desc]) => {
          return (
            <div key={element} className="flex flex-col gap-3 font-semibold">
              <h3>{element}</h3>
              <ViewsCard content={desc} />
            </div>
          );
        })}
      </div>
    </Section>
  );
}
TalkToUsSection.propTypes = {
  sectionData: PropTypes.object.isRequired,
};

// Main component
const HomeV = () => {
  const loading = useSelector((state) => state.homePage.loading);
  const error = useSelector((state) => state.homePage.error);
  const homeData = useSelector((state) => state.homePage.data);

  const { makeRequest: getHomeData } = useRequest("GET", {}, homeSlice);

  useEffect(() => {
    const getData = async () => {
      const [, statusCode, response] = await getHomeData("/home");

      handleResponse(
        response,
        statusCode,
        error,
        homeData,
        "Successfully fetched!"
      );
    };

    // console.log("Function call...");

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && !homeData) return <DashLoader message="Loading..." />;

  return (
    <div className="flex flex-col gap-8 pt-6 pb-10">
      <h1 className="text-xl self-center md:text-xl font-semibold">
        Home Page
      </h1>
      {homeData ? (
        <div className="flex flex-col gap-8">
          <HeroSection heroData={homeData?.hero} />
          <AboutSection aboutData={homeData?.about} />
          <ServicesSection servicesData={homeData?.services} />
          <TalkToUsSection sectionData={homeData?.section} />
        </div>
      ) : (
        <span>Fetching Data ...</span>
      )}
    </div>
  );
};

export default HomeV;
