export const partnerHeroSection = [
  {
    type: "text",
    name: "heroTitle",
    id: "heroTitle",
    label: "Title",
    placeholder: "We are a creative ...",
  },
  {
    type: "text",
    name: "heroBody",
    id: "heroBody",
    label: "Body",
    placeholder: "We design & create ...",
  },
  {
    type: "textArea",
    name: "heroPara",
    id: "heroPara",
    label: "Description",
    placeholder: "Elevate your business...",
  },
  {
    type: "text",
    name: "heroBtn",
    id: "heroBtn",
    label: "Button",
    placeholder: "Contact Us",
  },
];

export const partnerHeroTemplates = {
  heroTitle: "",
  heroBody: "",
  heroPara: "",
  heroBtn: "",
};

export const partnerBenefitsFieldArr = [
  {
    type: "text",
    name: "title",
    id: "title",
    label: "Title",
    placeholder: "",
  },
  {
    type: "textArea",
    name: "body",
    id: "body",
    label: "Body",
    placeholder: "",
  },
];

export const partnerBenefitsTemplates = {
  benefits: [
    {
      title: "",
      body: "",
    },
  ],
};

export const communitySection = [
  {
    type: "text",
    name: "communityTitle",
    id: "communityTitle",
    label: "Title",
    placeholder: "..",
  },
  {
    type: "text",
    name: "communityButton",
    id: "communityButton",
    label: "Button",
    placeholder: "...",
  },
];

export const communityTemplates = {
  communityTitle: "",
  communityButton: "",
};

export const sectionPartner = [
  {
    type: "text",
    name: "sectionTitle",
    id: "sectionTitle",
    label: "Title",
    placeholder: "..",
  },
  {
    type: "text",
    name: "sectionButton",
    id: "sectionButton",
    label: "Contact Button",
    placeholder: "...",
  },
];

export const sectionPartnerTemplates = {
  sectionTitle: "",
  sectionButton: "",
};
