export const careerHeroSection = [
  {
    type: "text",
    name: "heroTitle",
    id: "heroTitle",
    label: "Title",
    placeholder: "We are a creative ...",
  },
  {
    type: "textArea",
    name: "heroBody",
    id: "heroBody",
    label: "Body",
    placeholder: "We design & create ...",
  },
  {
    type: "textArea",
    name: "heroPara",
    id: "heroPara",
    label: "Description",
    placeholder: "Elevate your business...",
  },
  {
    type: "text",
    name: "heroBtn",
    id: "heroBtn",
    label: "Button",
    placeholder: "Contact Us",
  },
];

export const careerHeroTemplates = {
  heroTitle: "",
  heroButton: "",
  heroPara: "",
  heroBtn: "",
};

export const aboutCareerFieldArr = [
  {
    type: "text",
    name: "title",
    id: "title",
    label: "Title",
    placeholder: "",
  },
  {
    type: "textArea",
    name: "body",
    id: "body",
    label: "Body",
    placeholder: "",
  },
];

export const aboutCareertemplates = {
  aboutCareer: [
    {
      title: "",
      body: "",
    },
  ],
};

export const careerJoinUs = [
  {
    type: "text",
    name: "joinUsTitle",
    id: "joinUsTitle",
    label: "Title",
    placeholder: "..",
  },
  {
    type: "text",
    name: "joinUsButton",
    id: "joinUsButton",
    label: "Contact Button",
    placeholder: "...",
  },
];

export const careerJoinUsTemplates = {
  joinUsTitle: "",
  joinUsButton: "",
};

export const careerTalkToUs = [
  {
    type: "text",
    name: "talkToUsTitle",
    id: "talkToUsTitle",
    label: "Title",
    placeholder: "..",
  },
  {
    type: "text",
    name: "talkToUsButton",
    id: "talkToUsButton",
    label: "Contact Button",
    placeholder: "...",
  },
];

export const careerTalkToUsTemplates = {
  talkToUsTitle: "",
  talkToUsButton: "",
};

export const careerArrTemplates = [
  "heroTitle",
  "heroButton",
  "heroPara",
  "heroBtn",
  "aboutCareer",
  "joinUsTitle",
  "joinUsButton",
  "talkToUsTitle",
  "talkToUsButton",
];
