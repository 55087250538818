import { useEffect, useRef, useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  overviewSection,
  projectFieldArr,
  projectReviewsFieldArr,
  projectSection,
  projectSectionTemplates,
  summaryOneSection,
  summaryTwoSection,
} from "../../../../utils/dashboardFormData/otherPages/projectPageData";
import { useSelector } from "react-redux";
import useRequest from "../../../../components/hook/use-request";
import { projectSlice } from "../../../../redux/slice/project-slice";
import { handleResponse } from "../../../../utils/handleResponse";
import {
  reverseTransformApiData,
  transformApiData,
} from "../../../../utils/transformData";
import { projectSectionMap } from "../../../../utils/sectionMapData";
import DashLoader from "../../../../components/global/dash-loader";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import InputComponent from "../../../../components/dashboard/inputComponent";
import Label from "../../../../components/dashboard/label";
import InputField from "../../../../components/dashboard/inputField";
import TextArea from "../../../../components/dashboard/textArea";
import AddButton from "../../../../components/dashboard/addButton";
import RemoveButton from "../../../../components/dashboard/removeButton";
import FormWrapper from "../../../../components/dashboard/formWrapper";

// Overview section
function Overview({ register, projectIdx }) {
  return (
    <fieldset className="w-full flex flex-col gap-5">
      <SectionHeading content="Overview" />
      <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-5">
        {overviewSection.map((field, fieldIdx) => {
          return (
            <InputComponent className={`w-full`} key={fieldIdx}>
              <Label htmlFor={field.id} label={field.label} />
              <InputField
                type={field.type}
                name={`projects[${projectIdx}].overview[${field.name}]`}
                id={field.id}
                placeholder={field.placeholder}
                register={register(
                  `projects[${projectIdx}].overview[${field.name}]`
                )}
              />
            </InputComponent>
          );
        })}
      </div>
    </fieldset>
  );
}

// SummaryOne
function SummaryOne({ control, register, projectIdx }) {
  const fieldArrName = `projects.${projectIdx}.summaryOne.lists`;
  const {
    fields: summaryOneListFields,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: fieldArrName,
  });
  return (
    <fieldset className="w-full flex flex-col gap-5">
      <SectionHeading content="Summary One" className="mx-auto w-auto" />
      <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-5">
        {summaryOneSection.map((field, fieldIdx) => {
          return (
            <InputComponent className={`w-full`} key={fieldIdx}>
              <Label htmlFor={field.id} label={field.label} />
              <InputField
                type={field.type}
                name={`projects[${projectIdx}].summaryOne[${field.name}]`}
                id={field.id}
                placeholder={field.placeholder}
                register={register(
                  `projects[${projectIdx}].summaryOne[${field.name}]`
                )}
              />
            </InputComponent>
          );
        })}
      </div>

      {/* Summary one lists */}
      <div className="space-y-5">
        <AddButton
          onClick={() => appendItem({ items: "" })}
          content="Add Item"
          className={"gap-2 cols-span-2 lg:col-span-3"}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-5">
          {summaryOneListFields.map((listItem, itemIdx) => (
            <div className="flex gap-2" key={itemIdx}>
              <InputComponent className={`w-full`} key={listItem.id}>
                <Label htmlFor={"listItemsOne"} label={`List ${itemIdx + 1}`} />
                <InputField
                  type={"text"}
                  name={`${fieldArrName}.${itemIdx}.items`}
                  id={"listItemsOne"}
                  placeholder={`Item ${itemIdx + 1}`}
                  register={register(`${fieldArrName}.${itemIdx}..items`)}
                />
              </InputComponent>
              {itemIdx > 0 && (
                <RemoveButton
                  className={"self-end"}
                  onClick={() => removeItem(itemIdx)}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </fieldset>
  );
}
// SummaryTwo
function SummaryTwo({ control, register, projectIdx }) {
  const fieldArrName = `projects.${projectIdx}.summaryTwo.lists`;
  const {
    fields: summaryTwoListFields,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: fieldArrName,
  });
  return (
    <fieldset className="w-full flex flex-col gap-5">
      <SectionHeading content="Summary Two" className="" />
      <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-5">
        {summaryTwoSection.map((field, fieldIdx) => {
          return (
            <InputComponent className={`w-full`} key={fieldIdx}>
              <Label htmlFor={field.id} label={field.label} />
              <InputField
                type={field.type}
                name={`projects[${projectIdx}].summaryTwo[${field.name}]`}
                id={field.id}
                placeholder={field.placeholder}
                register={register(
                  `projects[${projectIdx}].summaryTwo[${field.name}]`
                )}
              />
            </InputComponent>
          );
        })}
      </div>

      {/* Summary Two lists */}
      <div className="space-y-5">
        <AddButton
          onClick={() => appendItem({ items: "" })}
          content="Add Item"
          className={"gap-2 cols-span-2 lg:col-span-3"}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-5">
          {summaryTwoListFields.map((listItem, itemIdx) => (
            <div className="flex gap-2" key={itemIdx}>
              <InputComponent className={`w-full`} key={listItem.id}>
                <Label htmlFor={"listItemsTwo"} label={`List ${itemIdx + 1}`} />
                <InputField
                  type={"text"}
                  name={`${fieldArrName}.${itemIdx}.items`}
                  id={"listItemsTwo"}
                  placeholder={`Item ${itemIdx + 1}`}
                  register={register(`${fieldArrName}.${itemIdx}..items`)}
                />
              </InputComponent>
              {itemIdx > 0 && (
                <RemoveButton
                  className={"self-end"}
                  onClick={() => removeItem(itemIdx)}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </fieldset>
  );
}

// Keywords
function Keywords({ control, register, projectIdx }) {
  const {
    fields: keywordFields,
    append: appendkeyword,
    remove: removeKeyword,
  } = useFieldArray({
    control,
    name: `projects.${projectIdx}.keywords`,
  });
  return (
    <div className="flex flex-col gap-3 col-span-2 w-full">
      <SectionHeading content="Keywords" />
      <div className="flex flex-col gap-3">
        {/* Field Array */}
        <div className="">
          <AddButton
            onClick={() => appendkeyword("")}
            content="Add Keyword"
            className={"gap-2"}
          />
        </div>
        <div className="gap-3 md:gap-8 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {keywordFields.map((keyword, keyIdx) => {
            return (
              <div key={keyword.id} className={`flex gap-2 w-full`}>
                <InputComponent className={`w-full`}>
                  <Label htmlFor={"keyword"} label={`Keyword ${keyIdx + 1}`} />
                  <InputField
                    type={keyword.type}
                    name={`projects[${projectIdx}].keywords[${keyIdx}]`}
                    id={"keyword"}
                    placeholder={`Keyword ${keyIdx + 1}`}
                    register={register(
                      `projects[${projectIdx}].keywords[${keyIdx}]`
                    )}
                  />
                </InputComponent>

                {keyIdx > 0 && (
                  <RemoveButton
                    className={"self-end"}
                    onClick={() => removeKeyword(keyIdx)}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

// Reviews
function Reviews({ control, register, projectIdx }) {
  const {
    fields: reviewFields,
    append: appendReview,
    remove: removeReview,
  } = useFieldArray({
    control,
    name: `projects.${projectIdx}.reviews`,
  });
  return (
    <div className="flex flex-col gap-3 w-full col-span-2">
      <SectionHeading content="Reviews" />
      <div className="flex flex-col gap-3 w-full">
        <div>
          <AddButton
            onClick={() => appendReview({ name: "", comment: "", image: "" })}
            content="Add Review"
            className={"gap-2"}
          />
        </div>
        {/* Field Array */}
        <div className="w-full flex flex-col gap-4">
          {reviewFields.map((review, reviewIdx) => {
            return (
              <div key={review.id} className="flex flex-col gap-3 w-full">
                <SectionHeading
                  content={`Review ${reviewIdx + 1}`}
                  className="bg-slate-700 text-white"
                />
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {projectReviewsFieldArr.map((reviewData, reviewDataIdx) => {
                    return (
                      <InputComponent key={reviewDataIdx} className="">
                        <Label
                          htmlFor={reviewData.id}
                          label={reviewData.label}
                        />
                        {reviewData.type === "text" && (
                          <InputField
                            type={reviewData.type}
                            name={`projects[${projectIdx}].reviews.${reviewData.name}`}
                            id={reviewData.id}
                            placeholder={reviewData.placeholder}
                            register={register(
                              `projects[${projectIdx}].reviews[${reviewIdx}].${reviewData.name}`
                            )}
                          />
                        )}
                        {reviewData.type === "textArea" && (
                          <TextArea
                            name={`projects[${projectIdx}].reviews.${reviewData.name}`}
                            id={reviewData.id}
                            placeholder={reviewData.placeholder}
                            register={register(
                              `projects[${projectIdx}].reviews[${reviewIdx}].${reviewData.name}`
                            )}
                          />
                        )}
                      </InputComponent>
                    );
                  })}
                  {reviewIdx > 0 && (
                    <RemoveButton
                      className={"self-end"}
                      onClick={() => removeReview(reviewIdx)}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

// Sub Project component
function ProjectSection() {
  const { register, control } = useFormContext();
  const {
    fields: projectFields,
    append: appendProject,
    remove: removeProject,
  } = useFieldArray({
    control,
    name: "projects",
  });
  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Projects" />
      <div className=" w-full space-y-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 w-full">
          {projectSection.map((field, index) => {
            return (
              <InputComponent key={index} className="">
                <Label htmlFor={field.id} label={field.label} />
                {field.type === "text" && (
                  <InputField
                    type={field.type}
                    name={field.name}
                    id={field.id}
                    placeholder={field.placeholder}
                    register={register(field.name)}
                  />
                )}
                {field.type === "textArea" && (
                  <TextArea
                    name={field.name}
                    id={field.id}
                    placeholder={field.placeholder}
                    register={register(field.name)}
                  />
                )}
              </InputComponent>
            );
          })}
        </div>

        {/* Field array section */}
        <div className="">
          <AddButton
            onClick={() =>
              appendProject({
                title: "",
                paragraph: "",
                image: null,
                keywords: [""],
                reviews: [{ name: "", comment: "", image: "" }],
              })
            }
            content="Add Project"
            className={"gap-2"}
          />
        </div>

        {/* Field Array */}
        <div className="w-full col-span-1 md:col-span-2 flex flex-col gap-10">
          {projectFields.map((project, projectIdx) => {
            return (
              <div
                key={project.id}
                className="flex flex-col gap-4 w-full self-center md:self-start pb-7 border border-x-0 border-t-0 border-b-slate-300"
              >
                <SectionHeading
                  content={`Project ${projectIdx + 1}`}
                  className="bg-slate-700 text-white"
                />
                {/* Projects */}
                <div className="w-full space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                    {projectFieldArr.map((entity, entityIdx) => {
                      return (
                        <InputComponent key={entityIdx} className="">
                          <Label htmlFor={entity.id} label={entity.label} />
                          {entity.type === "text" && (
                            <InputField
                              type={entity.type}
                              name={`projects[${projectIdx}].${entity.name}`}
                              id={entity.id}
                              placeholder={entity.placeholder}
                              register={register(
                                `projects[${projectIdx}].${entity.name}`
                              )}
                            />
                          )}
                          {entity.type === "textArea" && (
                            <TextArea
                              name={`projects[${projectIdx}].${entity.name}`}
                              id={entity.id}
                              placeholder={entity.placeholder}
                              register={register(
                                `projects[${projectIdx}].${entity.name}`
                              )}
                            />
                          )}
                        </InputComponent>
                      );
                    })}
                  </div>

                  {/* Overview */}
                  <Overview register={register} projectIdx={projectIdx} />

                  {/* SummaryOne */}
                  <SummaryOne
                    control={control}
                    projectIdx={projectIdx}
                    register={register}
                  />

                  {/* SummaryTwo */}
                  <SummaryTwo
                    control={control}
                    projectIdx={projectIdx}
                    register={register}
                  />

                  {/* Keywords */}
                  <Keywords
                    control={control}
                    register={register}
                    projectIdx={projectIdx}
                  />

                  {/* Reviews */}
                  <Reviews
                    control={control}
                    register={register}
                    projectIdx={projectIdx}
                  />

                  {projectIdx > 0 && (
                    <RemoveButton
                      className={"self-center mb-1"}
                      onClick={() => removeProject(projectIdx)}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
}

// Main Component
function ProjectPage() {
  const [isNew, setIsNew] = useState(true);
  const [response, setResponse] = useState(null);
  const [statusCode, setStatusCode] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);

  const methods = useForm({
    defaultValues: {
      ...projectSectionTemplates,
    },
  });
  const loading = useSelector((state) => state.projectPage.loading);
  const error = useSelector((state) => state.projectPage.error);
  const projectData = useSelector((state) => state.projectPage.data);

  const isInitialized = useRef(false);

  const { makeRequest: getProjectData } = useRequest("GET", {}, projectSlice);
  const { makeRequest: updateProjectData } = useRequest(
    "PUT",
    {},
    projectSlice
  );

  // GET FORM DATA
  useEffect(() => {
    const getData = async () => {
      setLoadingMessage("Getting data...");
      const [, initialStatusCode, initialResponse] = await getProjectData(
        "/project"
      );
      setResponse(initialResponse);
      setStatusCode(initialStatusCode);
      handleResponse(
        response,
        statusCode,
        error,
        projectData,
        "Successfully fetched!"
      );
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!projectData) {
      const transformedData = transformApiData(projectData, projectSectionMap);

      if (transformedData && Object.keys(transformedData).length > 0) {
        // Populate the form with fetched data
        methods.reset(transformedData);
        setIsNew(false);
        isInitialized.current = true;
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData]);

  // CREATE AND UPDATE FUNCTION
  const handleUpdate = async (data) => {
    const reversedTransformData = reverseTransformApiData(
      data,
      projectSectionMap
    );
    if (!isNew) {
      setLoadingMessage("Updating");
      const [, initialStatusCode, initialResponse] = await updateProjectData(
        "/project",
        reversedTransformData
      );
      setResponse(initialResponse);
      setStatusCode(initialStatusCode);
    }
    const toastMessage = "Successfully updated";

    const toastResponse = handleResponse(
      response,
      statusCode,
      error,
      projectData,
      toastMessage
    );
    if (toastResponse === "successful") return;
  };

  if (loading && loadingMessage === "Getting data...") {
    return <DashLoader message={loadingMessage} />;
  }

  return (
    <FormProvider {...methods}>
      <FormWrapper
        title="Projects Page"
        onSubmit={methods.handleSubmit(handleUpdate)}
        loading={
          (loadingMessage === "Updating" && loading) ||
          methods.formState.isSubmitting
        }
      >
        <ProjectSection />
      </FormWrapper>
    </FormProvider>
  );
}

export default ProjectPage;
