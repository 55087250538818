import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import {
  roleFieldArr,
  roleTemplates,
} from "../../../../utils/dashboardFormData/otherPages/rolePageData";
import { useSelector } from "react-redux";
import useRequest from "../../../../components/hook/use-request";
import { roleSlice } from "../../../../redux/slice/role-slice";
import { transformApiData } from "../../../../utils/transformData";
import { handleResponse } from "../../../../utils/handleResponse";
import DashLoader from "../../../../components/global/dash-loader";
import { roleSectionMap } from "../../../../utils/sectionMapData";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import AddButton from "../../../../components/dashboard/addButton";
import InputComponent from "../../../../components/dashboard/inputComponent";
import Label from "../../../../components/dashboard/label";
import InputField from "../../../../components/dashboard/inputField";
import RemoveButton from "../../../../components/dashboard/removeButton";
import TextArea from "../../../../components/dashboard/textArea";
import FormWrapper from "../../../../components/dashboard/formWrapper";

function RolePage() {
  const [response, setResponse] = useState(null);
  const [statusCode, setStatusCode] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [currentRole, setCurrentRole] = useState(null);

  const methods = useForm({
    defaultValues: {
      ...roleTemplates,
    },
  });
  const { register, control, watch } = methods;

  const {
    fields: roleFields,
    append: appendRole,
    remove: removeRole,
  } = useFieldArray({
    control,
    name: "roles",
  });

  const loading = useSelector((state) => state.rolePage.loading);
  const error = useSelector((state) => state.rolePage.error);
  const roleData = useSelector((state) => state.rolePage.data);

  const isInitialized = useRef(false);

  const { makeRequest: getRoleData } = useRequest("GET", {}, roleSlice);
  const { makeRequest: createRoleData } = useRequest("POST", {}, roleSlice);
  const { makeRequest: updateRoleData } = useRequest("PUT", {}, roleSlice);

  // GET FORM DATA
  useEffect(() => {
    const getData = async () => {
      setLoadingMessage("Getting data...");
      const [, initialStatusCode, initialResponse] = await getRoleData(
        "/career/role"
      );
      setResponse(initialResponse);
      setStatusCode(initialStatusCode);
      handleResponse(
        response,
        statusCode,
        error,
        roleData,
        "Successfully fetched!"
      );
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!roleData) {
      const transformedData = transformApiData(roleData, roleSectionMap);
      if (transformedData && Object.keys(transformedData).length > 0) {
        // Populate the form with fetched data
        methods.reset(transformedData);
        isInitialized.current = true;
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleData]);

  // WATCH FOR VALUES TO UPDATE
  useEffect(() => {
    const subscription = watch(async (values, { name, type }) => {
      if (type === "change" && name?.startsWith("roles")) {
        const index = parseInt(name.split("[")[1].split("]")[0], 10);

        // Role to update
        const roleToUpdate = values.roles[index];
        setCurrentRole(roleToUpdate);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  // UPDATE FUNCTION
  const updateRole = async () => {
    if (currentRole) {
      const roleId = currentRole?.id;
      setLoadingMessage("Updating data...");
      delete currentRole?.id;
      // Your update logic here
      const [updatedRole, updatedStatusCode, updatedResponse] =
        await updateRoleData(`/career/role/${roleId}`, currentRole);
      handleResponse(
        updatedResponse,
        updatedStatusCode,
        error,
        updatedRole,
        "Role updated successfully!"
      );

      // Refetch the updated data
      setLoadingMessage("Getting data...");
      const [, initialStatusCode, initialResponse] = await getRoleData(
        "/career/role"
      );
      setResponse(initialResponse);
      setStatusCode(initialStatusCode);
    }
  };

  // NEW DATA TO CREATE
  const newRoleData = {
    title: "",
    body: "",
    paragraph: "",
    btn: "",
    details: "",
    link: "",
  };

  // CREATE NEW DATA
  async function handleCreate(newData) {
    const [newRole, updateStatusCode, updatedResponse] = await createRoleData(
      "/career/role",
      newData
    );
    // Handle status code for update
    setStatusCode(updateStatusCode);

    // Display toast
    handleResponse(
      updatedResponse,
      statusCode,
      error,
      newRole,
      "Role created successfully!"
    );

    // Append new field to field array
    appendRole({
      title: "",
      body: "",
      paragraph: "",
      btn: "",
      details: "",
      link: "",
      id: newRole?._id,
    });

    // Fetch latest data (including the updated one)
    setLoadingMessage("Getting data...");
    const [, getStatusCode, getResponse] = await getRoleData("/career/role");
    setResponse(getResponse);
    setStatusCode(getStatusCode);
  }

  if (loading) {
    return <DashLoader message={loadingMessage} />;
  }

  return (
    <FormProvider {...methods}>
      <FormWrapper
        title="Roles Page"
        onSubmit={methods.handleSubmit(updateRole)}
        loading={
          (loadingMessage === "Updating" && loading) ||
          methods.formState.isSubmitting
        }
      >
        <Section className="flex flex-col gap-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            {/* Field array section */}
            <div className="md:col-span-2">
              <AddButton
                onClick={async () => await handleCreate(newRoleData)}
                content="Create Role"
                className={"gap-2"}
              />
            </div>

            {/* Field Array */}
            <div className="w-full col-span-1 md:col-span-2 flex flex-col gap-2">
              {roleFields.map((role, roleIdx) => {
                return (
                  <div
                    key={role.id}
                    className="flex flex-col gap-4 w-full self-center md:self-start"
                  >
                    <SectionHeading
                      content={`Role ${roleIdx + 1}`}
                      className="bg-slate-700 text-white"
                    />
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6 ">
                      {roleFieldArr.map((entity, entityIdx) => {
                        return (
                          <InputComponent key={entityIdx} className="">
                            <Label htmlFor={entity.id} label={entity.label} />
                            {entity.type === "text" && (
                              <InputField
                                type={entity.type}
                                name={`roles[${roleIdx}].${entity.name}`}
                                id={entity.id}
                                placeholder={entity.placeholder}
                                register={register(
                                  `roles[${roleIdx}].${entity.name}`
                                )}
                              />
                            )}
                            {entity.type === "textArea" && (
                              <TextArea
                                name={`roles[${roleIdx}].${entity.name}`}
                                id={entity.id}
                                placeholder={entity.placeholder}
                                register={register(
                                  `roles[${roleIdx}].${entity.name}`
                                )}
                              />
                            )}
                          </InputComponent>
                        );
                      })}

                      {roleIdx > 0 && (
                        <RemoveButton
                          className={"self-center mb-1"}
                          onClick={() => removeRole(roleIdx)}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Section>
      </FormWrapper>
    </FormProvider>
  );
}

export default RolePage;
