import React, { useState } from "react";
import Header from "./Header";
import MainSidebar from "./MainSidebar";
import ProjectSidebar from "./ProjectSidebar";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Icons } from "../global/icon";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slice/user-slice";

function Layout() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const isProjectsRoute = location.pathname.startsWith("/dashboard/projects");
  const isStatic = location.pathname.split("/").includes("static");
  const isViews = location.pathname.split("/").includes("views");
  const isBlogs = location.pathname.split("/").includes("blogs");

  function openSidebar() {
    setIsOpen(true);
  }
  function closeSidebar() {
    setIsOpen(false);
  }
  function handleLogout() {
    console.log("Processing logout...");
    dispatch(logout());
    console.log("logout successfuly");
  }
  return (
    <div className="w-full h-screen flex flex-col overflow-y-auto noScrollbar">
      <Header onClick={openSidebar} />

      {/* Mobile Menu */}
      {isOpen && (
        <aside className="bg-white/90 fixed inset-0 md:hidden w-full z-30 flex flex-col gap-5 pb-10 pt-10 overflow-y-auto">
          <button
            className="border border-black rounded-md self-center"
            onClick={closeSidebar}
          >
            <Icons.Cancel className="cursor-pointer md:hidden" />
          </button>
          {isProjectsRoute ? (
            <ProjectSidebar onClick={closeSidebar} />
          ) : (
            <MainSidebar onClick={closeSidebar} />
          )}
          <div className="flex flex-col gap-5 w-full items-center">
            <button
              className="flex gap-2 text-sm font-semibold"
              type="button"
              onClick={handleLogout}
            >
              <Icons.LogOut className="w-4 h-4 self-center" />
              <span>Logout</span>
            </button>
          </div>
        </aside>
      )}

      {/* Desktop sidebar */}
      <div className="flex flex-grow">
        <aside className="border-r border-gray-300 hidden md:flex md:flex-col md:items-center gap-9 w-[200px] pt-5 pb-8 fixed left-0 top-[60px] h-[calc(100vh-45px)] z-20">
          <Link className="flex gap-2 text-sm font-medium" to="/dashboard">
            <Icons.ArrowLeft className="w-4 h-4 self-center" />
            <span>Dashboard</span>
          </Link>
          {isProjectsRoute ? <ProjectSidebar /> : <MainSidebar />}
          <button
            className="flex gap-2 text-sm font-semibold mt-auto"
            type="button"
            onClick={handleLogout}
          >
            <Icons.LogOut className="w-4 h-4 self-center" />
            <span>Logout</span>
          </button>
        </aside>

        {/* Main */}
        <main
          className={`flex flex-col gap-5 w-full h-full px-7 py-5 pb-24 md:pb-6 ${
            isProjectsRoute ? "pt-[180px]" : "pt-[60px]"
          } md:ml-[200px] flex-grow `}
        >
          {!isProjectsRoute && (
            <h1 className="text-xl md:text-2xl font-semibold">
              Welcome John Doe!
            </h1>
          )}
          {isProjectsRoute && (
            <h1 className="text-xl italic md:text-2xl font-semibold fixed md:left-[200px] w-full px-7 top-[60px] z-10 py-5 pb-16 bg-white">
              <span>Pinnacle Padding</span>{" "}
              {isStatic
                ? "(Static Section)"
                : isBlogs
                ? "(Blogs Section)"
                : isViews
                ? "(Views Section)"
                : ""}
            </h1>
          )}

          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default Layout;
