export const projectSection = [
  {
    type: "text",
    name: "projectBody",
    id: "projectBody",
    label: "Body",
    placeholder: "Experience excellence",
  },
  {
    type: "textArea",
    name: "projectPara",
    id: "projectPara",
    label: "Paragraph",
    placeholder: "",
  },
];

export const projectFieldArr = [
  {
    type: "text",
    name: "title",
    id: "title",
    label: "Title",
    placeholder: "Project Title 1...",
  },
  {
    type: "textArea",
    name: "paragraph",
    id: "paragraph",
    label: "Paragraph",
    placeholder: "Detailed description of Project 1.",
  },
  {
    type: "text",
    name: "image",
    id: "image",
    label: "Project Image",
    placeholder: "Image url",
  },
];

export const overviewSection = [
  {
    type: "text",
    name: "client",
    id: "client",
    label: "Client",
    placeholder: "",
  },
  {
    type: "text",
    name: "service",
    id: "service",
    label: "Service",
    placeholder: "",
  },
  {
    type: "text",
    name: "duration",
    id: "duration",
    label: "Duration",
    placeholder: "",
  },
  {
    type: "text",
    name: "deliverable",
    id: "deliverable",
    label: "Deliverable",
    placeholder: "",
  },
];

export const summaryOneSection = [
  {
    type: "text",
    name: "paragraph",
    id: "paragraph",
    label: "Paragraph",
    placeholder: "",
  },
  {
    type: "text",
    name: "image",
    id: "image",
    label: "Image",
    placeholder: "",
  },
];

// SummaryOneLists field Arr

export const summaryTwoSection = [
  {
    type: "text",
    name: "paragraph",
    id: "paragraph",
    label: "Paragraph",
    placeholder: "",
  },
  {
    type: "text",
    name: "image",
    id: "image",
    label: "Image",
    placeholder: "",
  },
];

// Summary Two Lists field Arr

export const projectReviewsFieldArr = [
  {
    type: "text",
    name: "name",
    id: "name",
    label: "Customer's Name",
    placeholder: "Project Title 1...",
  },
  {
    type: "textArea",
    name: "comment",
    id: "comment",
    label: "Customer's Comment",
    placeholder: "",
  },
  {
    type: "text",
    name: "image",
    id: "image",
    label: "Customer's Image",
    placeholder: "Image url",
  },
];

export const projectSectionTemplates = {
  projectBody: "",
  projectPara: "",
  projects: [
    {
      title: "",
      paragraph: "",
      image: "",
      overview: {
        client: "",
        service: "",
        duration: "",
        deliverable: "",
      },
      summaryOne: {
        paragraph: "",
        image: "",
        lists: [
          {
            items: "",
          },
        ],
      },
      summaryTwo: {
        paragraph: "",
        image: "",
        lists: [
          {
            items: "",
          },
        ],
      },
      keywords: [""],
      reviews: [{ name: "", comment: "", image: "" }],
    },
  ],
};
