export const blogFieldArr = [
  {
    type: "text",
    name: "title",
    id: "title",
    label: "Title",
    placeholder: "",
  },
  {
    type: "text",
    name: "url",
    id: "url",
    label: "Blog URL",
    placeholder: "https://example.com/js-closures",
  },
  {
    type: "text",
    name: "author",
    id: "author",
    label: "Author",
    placeholder: "Jane Doe",
  },
  {
    type: "text",
    name: "readTime",
    id: "readTime",
    label: "Read Time",
    placeholder: "5 mins Read",
  },
  {
    type: "text",
    name: "image",
    id: "image",
    label: "Blog Image",
    placeholder: "https://example.com/images/js-closures.jpg",
  },
];

export const blogTemplates = {
  blogs: [
    {
      title: "",
      author: "",
      url: "",
      readTime: "",
      image: "",
      id: "",
    },
  ],
};
