export const roleFieldArr = [
  {
    type: "text",
    name: "title",
    id: "title",
    label: "Title",
    placeholder: "",
  },
  {
    type: "textArea",
    name: "body",
    id: "body",
    label: "Body",
    placeholder: "",
  },
  {
    type: "textArea",
    name: "paragraph",
    id: "paragraph",
    label: "Paragraph",
    placeholder: "",
  },
  {
    type: "text",
    name: "btn",
    id: "btn",
    label: "Button",
    placeholder: "",
  },
  {
    type: "text",
    name: "details",
    id: "details",
    label: "Details",
    placeholder: "",
  },
  {
    type: "text",
    name: "link",
    id: "link",
    label: "Link",
    placeholder: "https://example.com/apply-now",
  },
];

export const roleTemplates = {
  roles: [
    {
      title: "",
      body: "",
      paragraph: "",
      btn: "",
      details: "",
      link: "",
    },
  ],
};
