import { useDispatch } from "react-redux";
import { useState } from "react";

const baseURL =
  process.env.REACT_APP_BACKEND_URL ||
  "https://marxist-frannie-paddinghq-72ba64c8.koyeb.app/api/v1";

export default function useRequest(method, headers = {}, slice) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { startRequest, requestSuccess, requestFailure } = slice?.actions || {};

  // MAKE REQUEST FUNCTION
  async function makeRequest(endpoint, bodyData = null, params = {}) {
    setLoading(true);
    if (startRequest) {
      dispatch(startRequest());
    }

    const queryParams = new URLSearchParams(params).toString();
    const urlWithParams = queryParams
      ? `${baseURL}${endpoint}?${queryParams}`
      : `${baseURL}${endpoint}`;

    try {
      const response = await fetch(urlWithParams, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          ...headers,
        },
        body: ["POST", "PUT", "DELETE", "PATCH"].includes(method)
          ? JSON.stringify(bodyData)
          : undefined,
      });

      if (!response.ok) {
        throw new Error(`HTTP status ${response.status}`);
      }

      const json = await response.json();

      if (requestSuccess) {
        await dispatch(requestSuccess(json));
      }
      setLoading(false);

      return [json, response.status, response];
    } catch (error) {
      if (requestFailure) {
        await dispatch(requestFailure({ message: error.message }));
      }
      setLoading(false);
      return [null, 0, undefined];
    }
  }

  return { loading, makeRequest };
}
