import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useRequest from "../../../../components/hook/use-request";
import { projectSlice } from "../../../../redux/slice/project-slice";
import { toast } from "react-toastify";
import DashLoader from "../../../../components/global/dash-loader";
import PropTypes from "prop-types";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import { replaceObjectKeys } from "../../../../utils/utilsFunction";
import ViewsCard from "../../../../components/dashboard/viewsCard";

function ProjectSection({ projectSectionData }) {
  const filteredData = {
    body: projectSectionData?.body,
    paragraph: projectSectionData?.paragraph,
    projects: projectSectionData?.projects,
  };

  const newKeys = ["Body", "Paragraph", "Projects"];

  const newProjectData = replaceObjectKeys(filteredData, newKeys);

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Projects Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8">
        {Object.entries(newProjectData).map(([dataKey, dataValue]) => {
          if (dataKey === "Projects") {
            return (
              <div key={dataKey} className="flex flex-col gap-3 col-span-3">
                {/* Projects Header */}
                <SectionHeading content={dataKey} className="self-center" />
                {/* Each Project */}
                {dataValue.map((project, projectIdx) => {
                  return (
                    <div
                      key={projectIdx}
                      className="flex flex-col gap-5 border-b border-gray-500 pb-3 font-semibold col-span-3 w-full"
                    >
                      <div className="flex flex-col gap-2">
                        {/* Project subheading */}
                        <SectionHeading
                          content={`Project ${projectIdx + 1}`}
                          className="!self-start text-[15px]"
                        />
                        <div className="w-full space-y-4">
                          {/* Keywords */}
                          {project.keywords && project.keywords.length > 0 && (
                            <div className="space-y-2">
                              <h3 className="text-center border-b border-grey-700 pb-3">
                                Keywords
                              </h3>
                              <div className="flex gap-2 flex-wrap">
                                {project.keywords.map((keyword, keywordIdx) => {
                                  return (
                                    <ViewsCard
                                      key={keywordIdx}
                                      content={keyword}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          )}

                          {/* Rendering Reviews */}
                          {project.reviews && project.reviews.length > 0 && (
                            <div className="space-y-2">
                              <h3 className="text-center border-b border-grey-700 pb-3">
                                Reviews
                              </h3>
                              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                                {project.reviews.map((review, reviewIdx) => {
                                  return (
                                    // Each reviewer
                                    <div key={reviewIdx} className="space-y-2">
                                      <h4>{`Review ${reviewIdx + 1}`}</h4>
                                      <div className="space-y-2">
                                        <ViewsCard content={review.name} />
                                        <ViewsCard content={review.comment} />
                                        <ViewsCard
                                          content={
                                            <img
                                              src={review.image}
                                              alt={`Review ${reviewIdx}`}
                                            />
                                          }
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );

            // Body and Paragraph
          } else {
            return (
              <div key={dataKey} className="flex flex-col gap-3 font-semibold">
                <h3>{dataKey}</h3>
                <ViewsCard content={dataValue} />
              </div>
            );
          }
        })}
      </div>
    </Section>
  );
}

ProjectSection.propTypes = {
  projectSectionData: PropTypes.object.isRequired,
};

const ProjectV = () => {
  const loading = useSelector((state) => state.projectPage.loading);
  const error = useSelector((state) => state.projectPage.error);
  const projectData = useSelector((state) => state.projectPage.data);

  const { makeRequest: getProjectData } = useRequest("GET", {}, projectSlice);

  useEffect(() => {
    const getData = async () => {
      const [, statusCode, response] = await getProjectData("/project");
      if (response?.ok && statusCode === 200) {
        toast.success("Successfully fetched");

        return;
      }
      if (!response?.ok && statusCode === 400) {
        toast.error(`Unsuccessful: Bad request`);
        return;
      }
      if (!response?.ok && statusCode === 401) {
        toast.error(`Unauthorized...`);
        return;
      }
      if (!response?.ok && statusCode === 500) {
        toast.error(`Internal Server Error...Please try again`);
        return;
      }
      if (error && statusCode === 0 && projectData === null) {
        toast.error(error);
        return;
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && !projectData) return <DashLoader message="Loading..." />;
  return (
    <div className="flex flex-col gap-8 pt-6 pb-10">
      <h1 className="text-xl self-center md:text-xl font-semibold">
        Project Page
      </h1>
      {projectData ? (
        <div className="flex flex-col gap-8">
          <ProjectSection projectSectionData={projectData} />
        </div>
      ) : (
        <span>Fetching Data ...</span>
      )}
    </div>
  );
};

export default ProjectV;
