import React, { useEffect, useRef, useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import {
  aboutCareerFieldArr,
  aboutCareertemplates,
  careerArrTemplates,
  careerHeroSection,
  careerHeroTemplates,
  careerJoinUs,
  careerJoinUsTemplates,
  careerTalkToUs,
  careerTalkToUsTemplates,
} from "../../../../utils/dashboardFormData/otherPages/careerPageData";
import InputComponent from "../../../../components/dashboard/inputComponent";
import InputField from "../../../../components/dashboard/inputField";
import TextArea from "../../../../components/dashboard/textArea";
import Label from "../../../../components/dashboard/label";
import AddButton from "../../../../components/dashboard/addButton";
import RemoveButton from "../../../../components/dashboard/removeButton";
import { useSelector } from "react-redux";
import useRequest from "../../../../components/hook/use-request";
import { careerSlice } from "../../../../redux/slice/career-slice";
import { handleResponse } from "../../../../utils/handleResponse";
import {
  reverseTransformApiData,
  transformApiData,
} from "../../../../utils/transformData";
import DashLoader from "../../../../components/global/dash-loader";
import { careerSectionMap } from "../../../../utils/sectionMapData";
import FormWrapper from "../../../../components/dashboard/formWrapper";

function HeroSection() {
  const { register } = useFormContext();

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Hero Section" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        {careerHeroSection.map((field, index) => {
          return (
            <InputComponent key={index} className="">
              <Label htmlFor={field.id} label={field.label} />
              {field.type === "text" && (
                <InputField
                  type={field.type}
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
              {field.type === "textArea" && (
                <TextArea
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
            </InputComponent>
          );
        })}
      </div>
    </Section>
  );
}

function AboutSection() {
  const { register, control } = useFormContext();
  const {
    fields: aboutFields,
    append: appendAbout,
    remove: removeAbout,
  } = useFieldArray({
    control,
    name: "aboutCareer",
  });
  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="About Career" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        {/* Field array section */}
        <div className="md:col-span-2">
          <AddButton
            onClick={() =>
              appendAbout({
                title: "",
                body: "",
              })
            }
            content="Add About"
            className={"gap-2"}
          />
        </div>

        {/* Field Array */}
        <div className="w-full col-span-1 md:col-span-2 flex flex-col gap-10">
          {aboutFields.map((about, aboutIdx, aboutArr) => {
            return (
              <div
                key={about.id}
                className={`flex flex-col gap-4 w-full self-center md:self-start pb-7 ${
                  aboutIdx !== aboutArr.length - 1
                    ? "border-b border-b-slate-300"
                    : "border-0"
                }`}
              >
                <SectionHeading
                  content={`About ${aboutIdx + 1}`}
                  className="bg-slate-700 text-white"
                />
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6 ">
                  {aboutCareerFieldArr.map((entity, entityIdx) => {
                    return (
                      <InputComponent key={entityIdx} className="">
                        <Label htmlFor={entity.id} label={entity.label} />
                        {entity.type === "text" && (
                          <InputField
                            type={entity.type}
                            name={`aboutCareer[${aboutIdx}].${entity.name}`}
                            id={entity.id}
                            placeholder={entity.placeholder}
                            register={register(
                              `aboutCareer[${aboutIdx}].${entity.name}`
                            )}
                          />
                        )}
                        {entity.type === "textArea" && (
                          <TextArea
                            name={`aboutCareer[${aboutIdx}].${entity.name}`}
                            id={entity.id}
                            placeholder={entity.placeholder}
                            register={register(
                              `aboutCareer[${aboutIdx}].${entity.name}`
                            )}
                          />
                        )}
                      </InputComponent>
                    );
                  })}

                  {aboutIdx > 0 && (
                    <RemoveButton
                      className={"self-center mb-1"}
                      onClick={() => removeAbout(aboutIdx)}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
}

function JoinUsSection() {
  const { register } = useFormContext();
  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Join Us Section" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        {careerJoinUs.map((field, index) => {
          return (
            <InputComponent key={index} className="">
              <Label htmlFor={field.id} label={field.label} />
              {field.type === "text" && (
                <InputField
                  type={field.type}
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
            </InputComponent>
          );
        })}
      </div>
    </Section>
  );
}

function TalkToUsSection() {
  const { register } = useFormContext();
  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Talk To Us Section" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        {careerTalkToUs.map((field, index) => {
          return (
            <InputComponent key={index} className="">
              <Label htmlFor={field.id} label={field.label} />
              {field.type === "text" && (
                <InputField
                  type={field.type}
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
            </InputComponent>
          );
        })}
      </div>
    </Section>
  );
}

function CareersPage() {
  const [isNew, setIsNew] = useState(true);
  const [response, setResponse] = useState(null);
  const [statusCode, setStatusCode] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);

  const methods = useForm({
    defaultValues: {
      ...careerHeroTemplates,
      ...aboutCareertemplates,
      ...careerJoinUsTemplates,
      ...careerTalkToUsTemplates,
    },
  });

  const loading = useSelector((state) => state.careerPage.loading);
  const error = useSelector((state) => state.careerPage.error);
  const careerData = useSelector((state) => state.careerPage.data);

  const isInitialized = useRef(false);

  const { makeRequest: getCareerData } = useRequest("GET", {}, careerSlice);

  const { makeRequest: updateCareerData } = useRequest("PUT", {}, careerSlice);

  // GET FORM DATA
  useEffect(() => {
    const getData = async () => {
      setLoadingMessage("Getting data...");
      const [, initialStatusCode, initialResponse] = await getCareerData(
        "/career"
      );
      setResponse(initialResponse);
      setStatusCode(initialStatusCode);
      handleResponse(
        response,
        statusCode,
        error,
        careerData,
        "Successfully fetched!"
      );
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!careerData) {
      const transformedData = transformApiData(careerData, careerSectionMap);

      if (transformedData && Object.keys(transformedData).length > 0) {
        methods.reset(transformedData);
        setIsNew(false);
        isInitialized.current = true;
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careerData]);

  // CREATE AND UPDATE FUNCTION
  const handleUpdate = async (data) => {
    const reversedTransformData = reverseTransformApiData(
      data,
      careerSectionMap
    );
    if (!isNew) {
      setLoadingMessage("Updating");
      const [, initialStatusCode, initialResponse] = await updateCareerData(
        "/career",
        reversedTransformData
      );
      setResponse(initialResponse);
      setStatusCode(initialStatusCode);
    }
    const toastMessage = "Successfully updated";

    const toastResponse = handleResponse(
      response,
      statusCode,
      error,
      careerData,
      toastMessage
    );
    if (toastResponse === "successful") return;
  };

  if (loading && loadingMessage === "Getting data...") {
    return <DashLoader message={loadingMessage} />;
  }

  return (
    <FormProvider {...methods}>
      <FormWrapper
        title="Careers Page"
        onSubmit={methods.handleSubmit(handleUpdate)}
        loading={
          (loadingMessage === "Updating" && loading) ||
          methods.formState.isSubmitting
        }
      >
        <HeroSection />
        <AboutSection />
        <JoinUsSection />
        <TalkToUsSection />
      </FormWrapper>
    </FormProvider>
  );
}

export default CareersPage;
